import isEmpty from "is-empty";

import api from "../utils/api";
import apiUrls from "../config/apiUrls";
import {
  ADD_DISCOUNTS_AND_OFFERS,
  GET_ERRORS,
  GET_DISCOUNTS_AND_OFFERS,
  CLEAR_AGREEMENTS_DATA,
  SUBMIT_AGREEMENT,
  GET_ALL_AGREEMENTS,
  GET_PROCESS_DURATION,
  UN_ASSIGN_DOCUMENT,
  GET_AGREEMENT_APPROVAL_DETAILS,
  PARTNER_SIGN_AGREEMENT,
  STAKEHOLDER_SIGN_AGREEMENT,
  GET_AGREEMENT_REVIEW_DETAILS,
  COMMENT_ON_AGREEMENT,
  GENERATE_AGREEMENT,
  GET_PARTNERSHIP_DETAILS,
  GET_STRATEGIC_INDICATOR,
  GET_SELECTED_STRATEGIC_INDICATOR,
  SAVE_PARTNERSHIP_DETAILS,
  GET_SELECTION_CRITERIA,
  ASSIGN_TO_CONTACT,
  REMOVE_CONTACT,
  GET_AGREEMENT_LANGUAGE,
  GET_AGREMENT_FLOW,
  APPROVE_AGREEMENT,
  GET_AGREEMENT_INFO,
  SET_DEFAULT_CONTACT,
  SAVE_ADDITIONAL_PARTNERSHIP_DETAILS,
  ADD_CONTACT,
  VERIFY_SIGNING_TOKEN,
  ARCHIVE_AGREEMENT,
  UN_ARCHIVE_AGREEMENT,
  CLEAR_PARTNERSHIP_DETAILS,
  REQUEST_CANCEL_AGREEMENT,
  GET_ALL_CANCELLATION_REQUESTS,
  GET_CANCELLATION_REQUEST,
  LEGAL_CANCELLATION_APPROVAL,
  STAKEHOLDER_CANCELLATION_APPROVAL,
  HOLD_AGREEMENT,
  UN_HOLD_AGREEMENT,
  STAKEHOLDER_AGREEMENT_CANCELLATION,
  USER_AGREEMENT_CANCELLATION,
  ASSIGN_IN_DEPT,
  REQUEST_RENEW_AGREEMENT,
  REQUEST_AMEND_AGREEMENT,
  GET_AGREEMENT_REVIEW_HISTORY,
  GET_AGREEMENT_ACTIVITIES,
  UPLOAD_DOCUMENT_BY_STAKEHOLDER,
  GET_SIGNING_CEREMONY,
  GET_MEDIAS,
} from "../config/actionTypes";
import { setLoading, setMessage } from "./generalActions";
import { getPartnerDetails } from "./partnerActions";
import convertObjectToFormdata from "../utils/objectToFormdata";
import { isArabic } from "../i18n";

export const getAgreementInfo = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getAgreementInfo, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_AGREEMENT_INFO,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const addDiscountsAndOffers =
  (data, history, id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const formData = convertObjectToFormdata(data, ["Files"]);
      let res = null;

      if (id) {
        res = await api.put(
          `${apiUrls.editDiscountOffer}?code=${id}`,
          formData
        );
        history.push(`/request-mou/offers-and-discounts-agrmnt/${id}?step=2`);
      } else {
        res = await api.post(apiUrls.addDiscountOffer, formData);
        history.push(
          `/request-mou/offers-and-discounts-agrmnt/${res.data}?step=2`
        );
      }

      dispatch({
        type: ADD_DISCOUNTS_AND_OFFERS,
        payload: res.data,
      });
    } catch (err) {
      let error = "SomethingError";

      if (!isEmpty(err.response.data) && err.response.status < 500) {
        error = "discountAndOffers" + err.response.data.Message;
      }

      dispatch({
        type: GET_ERRORS,
        payload: { discountAndOffersError: error },
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getDiscountAndOffer = (params) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let res = await api.get(apiUrls.getDiscountOffer, { params });
    dispatch({
      type: GET_DISCOUNTS_AND_OFFERS,
      payload: res.data,
    });
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDiscountAndOfferWithPatnerDetails =
  (params) => async (dispatch, getState) => {
    await dispatch(getDiscountAndOffer(params));

    const discountsAndOffersData = getState().agreement.discountsAndOffersData;

    if (discountsAndOffersData)
      await dispatch(getPartnerDetails(discountsAndOffersData.PartnerId));
  };

export const submitAgreement = (data, history) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let res = await api.post(apiUrls.submitAgreement, data);
    dispatch({
      type: SUBMIT_AGREEMENT,
      payload: res.data,
    });

    dispatch(setMessage("Succesfully submitted your agreement proposal."));
    setTimeout(() => {
      history.push("/dashboard");
    }, 3000);
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const clearAgreementsData = () => {
  return {
    type: CLEAR_AGREEMENTS_DATA,
  };
};

export const addOutletsToAgreement = (data, code) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let res = await api.put(`${apiUrls.addOutlets}?Code=${code}`, data);
    if (res.data) {
      dispatch(setMessage("Added a new outlet successfully."));
      dispatch(getDiscountAndOffer({ Code: code }));
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const removeOutlet = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let res = await api.post(apiUrls.removeOutlets, data);

    dispatch(setMessage("Removed the outlet successfully."));
    dispatch(getDiscountAndOffer({ Code: data.Code }));
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const rejectAgreement = (data, history) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    let res = await api.post(apiUrls.rejectAgreement, data);

    if (res.status === 200) {
      dispatch(setMessage("Your comments has been sent."));

      setTimeout(() => {
        history.push("/manage-agreements");
      }, 3000);
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setLoading(false));
  }
};

export const agreementInitialApproval = (data, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    let res = await api.post(apiUrls.approveAgreement, data);

    if (res.status === 200) {
      dispatch({
        type: APPROVE_AGREEMENT,
      });
      dispatch(setMessage("Agreement has been approved successfully."));

      setTimeout(() => {
        history.push(`/dashboard`);
      }, 3000);
    }
  } catch (error) {
    if (error?.response.status === 400) {
      dispatch(setMessage(error?.response?.data?.Message));
    }
  } finally {
    dispatch(setLoading(false));
  }
};



export const reGenerateAgreement = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.post(apiUrls.regenearteAgreement, data);

    return res.status === 200;
  } catch (error) {
    // dispatch(setMessage("Something went wrong. Please try after sometime."));
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAgreements = (params) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const res = await api.get(apiUrls.getAllAgreements, { params });

    dispatch({
      type: GET_ALL_AGREEMENTS,
      payload: res.data,
    });
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getProcessDuration = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.get(apiUrls.getProcessDurations, { params: data });

    dispatch({
      type: GET_PROCESS_DURATION,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const assignDocumentTo =
  (data, user = "user") =>
    async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const res = await api.post(apiUrls.assignDocumentForEdit, data);
        if (res.status === 200) {
          dispatch(setMessage(`Successfully assigned agreement to ${user} `));
          return true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    };

export const assignMultipleDocumentTo =
  (data, user = "user") =>
    async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const res = await api.post(apiUrls.assignDocEditMultipleUsers, data);
        if (res.status === 200) {
          dispatch(setMessage(`Successfully assigned agreement `));

          return true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    };

export const assignDocumentInDept =
  (data, user = "Dept User") =>
    async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const res = await api.post(apiUrls.assignWithinDept, data);

        if (res.status === 200) {
          dispatch({
            type: ASSIGN_IN_DEPT,
          });
          dispatch(setMessage(`Successfully assigned agreement to ${user} `));
          return true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    };

export const unAssignUserFromDocument =
  (data, agreementId) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const res = await api.post(apiUrls.unAssignUserFromDocument, data);

      if (res.status === 200) {
        dispatch({
          type: UN_ASSIGN_DOCUMENT,
        });

        await dispatch(setMessage("User unassigned from this agreement."));

        return true;
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getAgreementApprovalDetails = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getAgreementApprovalDetails, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_AGREEMENT_APPROVAL_DETAILS,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAgreementReviewDetails = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getMyAgreementReviewDetails, { params });

    if (res.status === 200)
      dispatch({
        type: GET_AGREEMENT_REVIEW_DETAILS,
        payload: res.data,
      });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const postCollaboratorComment = (data, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.commentAgreement, data);
    if (res.status === 200) {
      dispatch({ type: COMMENT_ON_AGREEMENT });
      await dispatch(
        setMessage(
          "Your comment has been sent successfully to the stakeholder."
        )
      );

      setTimeout(() => {
        history.push("/manage-agreements");
      }, 2000);
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const postCollaboratorCommentWithDocument =
  (data, history) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const formData = convertObjectToFormdata(data);

      const res = await api.post(
        apiUrls.commentAgreementWithCustomTemplate,
        formData
      );

      if (res.status === 200) {
        dispatch({ type: COMMENT_ON_AGREEMENT });
        await dispatch(
          setMessage(
            "Your comment has been sent successfully to the stakeholder."
          )
        );

        setTimeout(() => {
          history.push("/manage-agreements");
        }, 2000);
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

export const uploadEditedDocument = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const formData = convertObjectToFormdata(data);

    const res = await api.post(
      apiUrls.uploadEditedAgreementByStakeholder,
      formData
    );

    if (res.status === 200) {
      dispatch({ type: UPLOAD_DOCUMENT_BY_STAKEHOLDER });
      await dispatch(setMessage("Successfully updated the document "));
      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

// export const approveAgreement =
//   (data, isStakeholder, history) => async (dispatch) => {
//     try {
//       dispatch(setLoading(true));

//       let res = null;

//       if (isStakeholder) {
//         res = await api.post(apiUrls.stakeholderAgreementApproval, data);
//       } else {
//         res = await api.post(apiUrls.partnerAgreementApproval, data);
//       }

//       if (res.status === 200) {
//         dispatch(
//           setMessage(`${data.AgreementCode} has been successfully approved.`)
//         );

//         setTimeout(() => {
//           // history.push("/manage-agreements");
//           window.location.reload();
//           history.push("/agreement/partnership/track/" + data.AgreementCode);
//         }, 3000);
//       }
//     } catch (error) {
//       if (error.response?.data) {
//         dispatch(
//           setMessage(
//             error.response?.data?.Message ||
//               "Something went wrong. Please try again."
//           )
//         );
//       } else {
//         dispatch(setMessage("Something went wrong. Please try again."));
//       }
//     } finally {
//       dispatch(setLoading(false));
//     }
//   };

export const approveAgreement =
  (data, isStakeholder, history) => async (dispatch) => {


    try {
      dispatch(setLoading(true));

      let resStakeholder = null;
      let res = null;

      if (isStakeholder) {
        resStakeholder = await api.post(
          apiUrls.stakeholderAgreementApproval,
          data
        );

        if (data.IsSigningCeremonyNeeded) {
          if (resStakeholder.status === 200) {
            dispatch(
              setMessage(
                `${data.AgreementCode} has been successfully approved.`
              )
            );

            history.push("/signing-ceremony/" + data.AgreementCode);
          }
        } else {
          if (resStakeholder.status === 200) {
            dispatch(
              setMessage(
                `${data.AgreementCode} has been successfully approved.`
              )
            );

            history.push("/agreement/partnership/track/" + data.AgreementCode);
          }
        }
      } else {
        res = await api.post(apiUrls.partnerAgreementApproval, data);

        if (res.status === 200) {
          dispatch(
            setMessage(`${data.AgreementCode} has been successfully approved.`)
          );
          history.push("/agreement/partnership/track/" + data.AgreementCode);
        }
      }
    } catch (error) {
      if (error.response?.data) {
        dispatch(
          setMessage(
            error.response?.data?.Message ||
            "Something went wrong. Please try again."
          )
        );
      } else {
        dispatch(setMessage("Something went wrong. Please try again."));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const signAgreement =
  (data, isStakeholder, history) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();

      formData.append("File", data.File);
      formData.append("Code", data.Code);

      if (isStakeholder) {
        const res = await api.post(apiUrls.stakeholderAgreementSign, formData);

        if (res.status === 200) {
          dispatch({
            type: STAKEHOLDER_SIGN_AGREEMENT,
          });

          dispatch(setMessage("Agreement has been signed successfully."));
        }
      } else {
        const res = await api.post(apiUrls.partnerAgreementSign, formData);

        if (res.status === 200) {
          dispatch({
            type: PARTNER_SIGN_AGREEMENT,
          });

          dispatch(setMessage("Agreement has been signed successfully."));
        }
      }

      setTimeout(() => {
        history.push("/manage-agreements");
      }, 3000);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

// Partnership agreements

export const getAgreementLanguage = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.get(apiUrls.getAgreementLanguages, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_AGREEMENT_LANGUAGE,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getPartnershipDetails = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.get(apiUrls.getPartnershipDetails, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_PARTNERSHIP_DETAILS,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStrategicIndicator = (params) => async (dispatch) => {

  try {
    dispatch(setLoading(true));
    const res = await api.get(
      `${apiUrls.getAllStrategicIndicator}?code=${params.code}&lang=${params.lang}`
    );

    if (res.status === 200) {
      dispatch({
        type: GET_STRATEGIC_INDICATOR,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getSelectedStrategicIndicator = (params) => async (dispatch) => {

  try {
    dispatch(setLoading(true));
    const res = await api.get(
      `${apiUrls.getStrategicIndicator}?code=${params.code}&lang=${params.lang}`
    );

    if (res.status === 200) {
      dispatch({
        type: GET_SELECTED_STRATEGIC_INDICATOR,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getSigningCeremony = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.get(apiUrls.getSigningCeremony, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_SIGNING_CEREMONY,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMedias = (params) => async (dispatch) => {

  try {
    dispatch(setLoading(true));
    // const res = await api.get(apiUrls.getMedias, { params });

    // const res = await api.get(`${apiUrls.getMedias}/${params.Id}`);

    const res = await api.get(`${apiUrls.getMedias}?Code=${params}`);

    if (res.status === 200) {
      dispatch({
        type: GET_MEDIAS,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const savePartnershipDetails = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const formData = convertObjectToFormdata(data, ["Files"]);
    const res = await api.post(apiUrls.savePartnershipDetails, formData);

    if (res.status === 201 || res.status === 200) {
      dispatch({
        type: SAVE_PARTNERSHIP_DETAILS,
      });

      dispatch(setMessage("Successfully saved partnership details."));

      return res.data;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const clearPartnershipDetails = () => ({
  type: CLEAR_PARTNERSHIP_DETAILS,
});

export const savePartnershipAdditionalDetails =
  (data, history) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const formData = convertObjectToFormdata(data, ["Docs"]);
      const res = await api.post(apiUrls.savePartnershipAdditional, formData);

      if (res.status === 201 || res.status === 200) {
        dispatch({
          type: SAVE_ADDITIONAL_PARTNERSHIP_DETAILS,
        });

        dispatch(setMessage("Succesfully submitted your agreement proposal."));
        setTimeout(() => {
          history.push("/dashboard");
        }, 3000);

        return true;
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updatePartnershipAdditionalDetails =
  (data, history) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const formData = convertObjectToFormdata(data, ["Docs"]);
      const res = await api.post(apiUrls.savePartnershipAdditional, formData);

      if (res.status === 201 || res.status === 200) {
        dispatch({
          type: SAVE_ADDITIONAL_PARTNERSHIP_DETAILS,
        });

        dispatch(setMessage("Succesfully updated your agreement details."));

        return true;
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getSelectionCriteria = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getSelectionCriteria, { params });

    if (res.status === 200) {
      dispatch({ type: GET_SELECTION_CRITERIA, payload: res.data });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const saveSelectionCriteria =
  (agreementId, data) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const res = await api.put(
        `${apiUrls.saveSelectionCriteria}?Code=${agreementId}`,
        data
      );

      if (res.status === 200) {
        dispatch(setMessage("Successfully saved selection criteria."));
        return true;
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

export const assignToContact = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.assignContact, data);

    if (res.status === 200) {
      dispatch({
        type: ASSIGN_TO_CONTACT,
      });
      dispatch(
        getPartnershipDetails({ lang: isArabic ? "ar" : "en", code: data.Code })
      );

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const addContact = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.addNewContact, data);

    if (res.status === 200 || res.status === 201) {
      dispatch({
        type: ADD_CONTACT,
      });
      dispatch(
        getPartnershipDetails({ lang: isArabic ? "ar" : "en", code: data.Code })
      );

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const removeContact = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.removeContact, data);

    if (res.status === 200) {
      dispatch({
        type: REMOVE_CONTACT,
      });
      dispatch(
        getPartnershipDetails({ lang: isArabic ? "ar" : "en", code: data.Code })
      );
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const makedefaultContact = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.makeDefaultContact, data);

    if (res.status === 200) {
      dispatch({
        type: SET_DEFAULT_CONTACT,
      });
      dispatch(
        getPartnershipDetails({ lang: isArabic ? "ar" : "en", code: data.Code })
      );
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAgreementFlow = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getAgreementFlow, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_AGREMENT_FLOW,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const signAgreementByDED = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const formData = new FormData();

    formData.append("File", data.File);
    formData.append("Code", data.Code);

    const res = await api.post(apiUrls.dedAgreementSign, formData);

    if (res.status === 200) {
      dispatch({
        type: STAKEHOLDER_SIGN_AGREEMENT,
      });

      dispatch(setMessage("Agreement has been signed successfully."));

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const verifySigningToken = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.verifySigningToken, { params });

    if (res.status === 200) {
      api.defaults.headers.common["TempToken"] = res.data;
      dispatch({
        type: VERIFY_SIGNING_TOKEN,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const archiveAgreement = (code, isArchive) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    let res;
    if (isArchive)
      res = await api.put(`${apiUrls.archiveAgreement}?code=${code}`);
    else res = await api.put(`${apiUrls.unArchiveAgreement}?code=${code}`);

    if (res.status === 200) {
      const type = isArchive ? ARCHIVE_AGREEMENT : UN_ARCHIVE_AGREEMENT;
      dispatch({
        type,
      });

      dispatch(
        setMessage(
          `Agreement ${code} has been ${isArchive ? "archived" : "unarchived"
          } successfully.`
        )
      );

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const agreementCancellationByStakeholder =
  (data) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      let res = await api.post(apiUrls.stakeholderCancellation, data);

      if (res.status === 200) {
        dispatch({
          type: STAKEHOLDER_AGREEMENT_CANCELLATION,
        });

        dispatch(setMessage(`Agreement ${data.Code} has been cancelled.`));

        return true;
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

export const agreementCancellationByUser = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    let res = await api.post(apiUrls.userCancellation, data);

    if (res.status === 200) {
      dispatch({
        type: USER_AGREEMENT_CANCELLATION,
      });

      dispatch(setMessage(`Agreement ${data.Code} has been cancelled.`));

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const requestCancellation = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const formData = convertObjectToFormdata(data);

    let res = await api.post(apiUrls.requestAgreementCancellation, formData);

    if (res.status === 200) {
      dispatch({
        type: REQUEST_CANCEL_AGREEMENT,
      });

      dispatch(
        setMessage(
          `Your request for canceling ${data.Code} has been sent to legal department.`
        )
      );

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAllCancellationRequests = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    let res = await api.get(apiUrls.getAllCancellationRequest, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_ALL_CANCELLATION_REQUESTS,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getCancellationRequestDetails = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    let res = await api.get(apiUrls.getCancellationRequest, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_CANCELLATION_REQUEST,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const agreementCancellationApproval =
  (data, userType) => async (dispatch) => {
    try {
      dispatch(setLoading(true));

      let res = null,
        type = "";

      if (userType === "legal") {
        type = LEGAL_CANCELLATION_APPROVAL;
        res = await api.post(apiUrls.legalCancellationApproval, data);
      } else {
        type = STAKEHOLDER_CANCELLATION_APPROVAL;
        res = await api.post(apiUrls.stakeholderCancellationApproval, data);
      }

      if (res.status === 200) {
        dispatch({
          type,
        });

        dispatch(
          setMessage(`Successfully updated your cancellation request review. `)
        );

        return true;
      }
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };

export const toggleAgreementHold = (data, isOnhold) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let res = null,
      type = "",
      message = "";
    if (isOnhold) {
      type = HOLD_AGREEMENT;
      res = await api.post(apiUrls.onholdAgreement, data);
      message = `Agreement ${data.Code} has been put on-hold status.`;
    } else {
      type = UN_HOLD_AGREEMENT;
      res = await api.post(apiUrls.releaseHoldAgreement, data);
      message = `Agreement ${data.Code} has been released from on-hold status.`;
    }
    if (res.status === 200) {
      dispatch({
        type,
      });
      dispatch(setMessage(message));
      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};


export const generateAgreement = (data) => async (dispatch) => {



  dispatch(setLoading(true));
  try {
    let res = await api.post(apiUrls.generateAgreement, data);

    if (res.status === 200) {
      dispatch({
        type: GENERATE_AGREEMENT,
      });
      dispatch(setMessage("Agreement has been generated Successfully."));
      return res.data;
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setLoading(false));
  }
};



export const agreementRenewalRequest = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const formData = convertObjectToFormdata(data);

    let res = await api.post(apiUrls.requestAgreementRenewal, formData);

    if (res.status === 200) {
      dispatch({
        type: REQUEST_RENEW_AGREEMENT,
      });

      return res.data;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const agreementAmendRequest = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const formData = convertObjectToFormdata(data, ["UploadImages"]);

    let res = await api.post(apiUrls.requestAgreementAmend, formData);

    if (res.status === 200) {
      dispatch({
        type: REQUEST_AMEND_AGREEMENT,
      });

      return res.data;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAgreementReviewHistory = (params) => async (dispacth) => {
  try {
    const res = await api.get(apiUrls.getAgreementReviewHistory, { params });

    if (res.status === 200) {
      dispacth({
        type: GET_AGREEMENT_REVIEW_HISTORY,
        payload: res.data,
      });
    }
  } catch (error) { }
};

export const getAgreementActivities = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.get(apiUrls.getAgreementActivities, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_AGREEMENT_ACTIVITIES,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};
