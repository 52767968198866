import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import PreLoader from "../PreLoader";

const LTR = React.lazy(() => import("./ltr"));
const RTL = React.lazy(() => import("./rtl"));

const DirectionSelector = ({ children }) => {
  const { i18n } = useTranslation();
  const isAr = () => i18n.language === "ar";
  return (
    <>
      <Suspense fallback={<PreLoader />}>{isAr ? <RTL /> : <LTR />}</Suspense>
      {children}
    </>
  );
};

export default DirectionSelector;
