import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Textfield from "../../components/Form/Textfield";
import Alert from "../../components/Alert";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Textarea from "../../components/Form/TextArea";
import { propTypes } from "react-bootstrap/esm/Image";
import {
  updateViewCount,
  getNotificationCount,
} from "../../actions/authActions";
import { useHistory } from "react-router-dom";
import { TiArrowBackOutline } from "react-icons/ti";
import moment from "moment";

const InboxView = (props) => {
  const location = useLocation();
  let history = useHistory();
  const id = location.state?.detail.Id;

  useEffect(() => {
    updateViewCount({
      id,
    });
  }, []);

  const initialValues = {
    FirstName: location.state?.detail?.SendByFirstName
      ? location.state?.detail?.SendByFirstName
      : "",
    LastName: location.state?.detail?.SendByLastName
      ? location.state?.detail?.SendByLastName
      : "",
    Email: location.state?.detail?.SendByEmail
      ? location.state?.detail?.SendByEmail
      : "",
    Subject: location.state?.detail?.Subject
      ? location.state?.detail?.Subject
      : "",
    Message: location.state?.detail?.Message
      ? location.state?.detail?.Message
      : "",
  };

  const backTo = (item) => {
    getNotificationCount();
    history.push({
      pathname: "/connect/inbox",
    });
    window.location.reload();
  };


  return (
    <>
      <div className="page-body navbar-page">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-block ">
                <Row className="px-3">
                  <Col lg={10}>
                    <h5 className="mb-3 text-primary">Inbox View</h5>
                  </Col>
                  <Col lg={2} className="d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={backTo}
                      className="btn btn-blue-green  "
                    >
                      <TiArrowBackOutline /> Back
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="inbox-time">
                      <h5>
                        {moment(location.state?.detail?.SendOn).format("LLLL")}
                      </h5>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="inbox-wrap">
                      <ul className="entity-detail mb-4">
                        <li className="d-flex mt-2">
                          <strong>From:</strong>
                          <p className="m-0">
                            {` ${location.state?.detail?.SendByFirstName} ${location.state?.detail?.SendByLastName}`}
                          </p>
                        </li>
                        <li className="d-flex mt-2">
                          <strong>Sender Email:</strong>
                          <p className="m-0">
                            {location.state?.detail?.SendByEmail}
                          </p>
                        </li>
                        <li className="d-flex mt-2">
                          <strong>Subject:</strong>
                          <p className="m-0">
                            {location.state?.detail?.Subject}
                          </p>
                        </li>
                        <li className="d-flex mt-2 py-3">
                          <strong>Message:</strong>
                          <p className="m-0">
                            {location.state?.detail?.Message}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-12">
            <div className="card">
              <div className="card-block">
                <Row>
                  <Col lg={11}>
                    <h4 className="mb-3 text-primary">Inbox View</h4>
                  </Col>
                  <Col lg={1}>
                    <button
                      type="button"
                      onClick={backTo}
                      className="btn btn-blue-green btn-block "
                    >
                      Back
                    </button>
                  </Col>
                </Row>
                <Formik initialValues={initialValues} enableReinitialize={true}>
                  {({
                    touched,
                    errors,
                    handleChange,
                    isSubmitting,
                    values,
                  }) => (
                    <Form className="mb-3">
                      <Row>
                        <div className="form-group col-sm-6">
                          <label className="small">First Name</label>
                          <Textfield
                            autoFocus
                            name="FirstName"
                            type="text"
                            readOnly
                          />
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="small">Last Name</label>
                          <Textfield type="text" name="LastName" readOnly />
                        </div>

                        <div className="form-group col-sm-6">
                          <label className="small"> Subject</label>
                          <Textfield type="text" name="Subject" readOnly />
                        </div>
                        <div className="form-group col-sm-6">
                          <label className="small">Email</label>
                          <Textfield
                            name="Email"
                            placeholder="Email"
                            readOnly
                          />
                        </div>

                        <div className="form-group col-sm-12">
                          <label className="small">Message</label>
                          <Textarea type="text" name="Message" readOnly />
                        </div>

                        
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default InboxView;
