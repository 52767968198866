import { GET_REPORT_INFO, GET_TYPES, RESET_ALL } from "../config/actionTypes";

const initialState = {
  ln: "en",
  reportInfo: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REPORT_INFO:
      return {
        ...state,
        reportInfo: action.payload,
      };
    case GET_TYPES:
      return {
        ...state,
        reportTypes: action.payload,
      };
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
