import React, { useState, useEffect } from "react";
import { Plus, Minus } from "react-feather";
import { Button, Collapse } from "react-bootstrap";

const SingleAccordion = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.defaultOpen) setOpen(true);
  }, []);
  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        block
        variant="light"
        className="border text-primary text-uppercase d-flex justify-content-between align-items-center"
      >
        {props.title} {open ? <Minus size={14} /> : <Plus size={14} />}
      </Button>
      <Collapse in={open}>
        <div>
          <div className="p-3">{props.children}</div>
        </div>
      </Collapse>
    </>
  );
};

export default SingleAccordion;
