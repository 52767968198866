import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";

import PrivateRoute from "./components/PrivateRoute";
import PreLoader from "./components/PreLoader";
import Layout from "./components/Layout";
import appUrls from "./config/appUrls";
import StrategicIndicator from "./screens/partnershipAgreement/StrategicIndicator";
import InboxView from "./screens/inbox/InboxView";
import signingCeremony from "./screens/signingCeremony";

const Login = React.lazy(() => import("./screens/login"));
const ForgotPassword = React.lazy(() => import("./screens/forgotPassword"));
const ResetPassword = React.lazy(() => import("./screens/resetPassword"));
const Register = React.lazy(() => import("./screens/register"));
const Dashboard = React.lazy(() => import("./screens/dashboard"));
const UserProfile = React.lazy(() => import("./screens/userProfile"));
const Report = React.lazy(() => import("./screens/report"));
const EditUserProfile = React.lazy(() => import("./screens/editUserProfile"));
const PartnerProfile = React.lazy(() => import("./screens/partnerProfile"));
const EditPartnerProfile = React.lazy(() =>
  import("./screens/editPartnerProfile")
);
const Error = React.lazy(() => import("./screens/error"));
const Notification = React.lazy(() => import("./screens/notification"));
const MyMeetingView = React.lazy(() => import("./screens/myMeetingView"));
const RegistrationReview = React.lazy(() =>
  import("./screens/registrationReview")
);
const RequestMou = React.lazy(() => import("./screens/requestmou"));

const OffersDiscountsAgreement = React.lazy(() =>
  import("./screens/offersDiscountsAgreement")
);
const GeneralEnquiry = React.lazy(() => import("./screens/generalEnquiry"));

const PartnershipAgreement = React.lazy(() =>
  import("./screens/partnershipAgreement")
);

const TrackAgreement = React.lazy(() => import("./screens/trackAgreement"));
const TrackPartnershipAgreement = React.lazy(() =>
  import("./screens/trackPartnershipAgreement")
);

const ManageAgreement = React.lazy(() => import("./screens/manageAgreement"));
const ReviewAgreementRequest = React.lazy(() =>
  import("./screens/agreementReview")
);
const ReviewPartnershipAgreementRequest = React.lazy(() =>
  import("./screens/patnershipAgreementReview")
);

const ReviewPartnershipAgreementRequestByPartner = React.lazy(() =>
  import("./screens/partnershipAgreementReviewByPartner")
);

const MouDetails = React.lazy(() => import("./screens/moudetails"));
const Document = React.lazy(() => import("./screens/documentEditor"));
const SignAgreement = React.lazy(() => import("./screens/signAgreement"));
const SignPartnershipAgreement = React.lazy(() =>
  import("./screens/signPartnershipAgreement")
);
const SignPartnershipAgreementPublic = React.lazy(() =>
  import("./screens/signPartnershipAgreementPublic")
);

const RequestCancelAgreement = React.lazy(() =>
  import("./screens/agreementCancellationRequest")
);
const ReviewCancelAgreement = React.lazy(() =>
  import("./screens/agreementCancellationReview")
);

const RenewAgreementRequest = React.lazy(() =>
  import("./screens/agreementRenewalRequest")
);
const AmendAgreementRequest = React.lazy(() =>
  import("./screens/agreementAmendRequest")
);

const ManagePartners = React.lazy(() => import("./screens/managePartners"));
const AddEditPartner = React.lazy(() => import("./screens/addEditPartner"));

const ManageUsers = React.lazy(() => import("./screens/manageUsers"));
const AddEditUser = React.lazy(() => import("./screens/addEditUser"));

const Inbox = React.lazy(() => import("./screens/inbox"));
const Sent = React.lazy(() => import("./screens/sentBox"));

const OutsourcingChecklist = React.lazy(() =>
  import("./screens/outsourcingChecklist")
);

const ScheduleMeeting = React.lazy(() => import("./screens/scheduleMeeting"));
const SigningCeremony = React.lazy(() => import("./screens/signingCeremony"));
const SignedDocuments = React.lazy(() => import("./screens/signedDocuments"));

const MyMeetings = React.lazy(() => import("./screens/myMeetings"));

const RequestCounsel = React.lazy(() => import("./screens/requestCounsel"));
const ReviewCounsel = React.lazy(() => import("./screens/counselReview"));
const ManageCounsels = React.lazy(() => import("./screens/manageCounsels"));

const AppRoutes = () => {
  const { i18n } = useTranslation();
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }} />
      <Router>
        <Suspense fallback={<PreLoader />}>
          <Switch>
            <Route path={appUrls.confirmEmail} component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/register/:regId" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <PrivateRoute
              exact
              path={["/schedule-meeting/:agreementId", "/schedule-meeting"]}
              component={ScheduleMeeting}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/my-meetings"
              component={MyMeetings}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/my-meetings/:id"
              component={MyMeetingView}
              layout={Layout}
            />

            <PrivateRoute
              exact
              path="/signing-ceremony/:agreementId"
              component={SigningCeremony}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/signed-documents/:agreementId"
              component={SignedDocuments}
              layout={Layout}
            />

            <PrivateRoute
              exact
              path="/profile"
              component={UserProfile}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/connect/inbox"
              component={Inbox}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/connect/sent"
              component={Sent}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/profile/edit"
              component={EditUserProfile}
              layout={Layout}
            />
            <PrivateRoute
              path="/document/edit"
              component={Document}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={["/dashboard", "/"]}
              layout={Layout}
              component={Dashboard}
            />
            <PrivateRoute
              exact
              path={["/my-company-profile", "/partner/:id"]}
              layout={Layout}
              component={PartnerProfile}
            />
            <PrivateRoute
              exact
              path={[
                "/my-company-profile/edit",
                "/my-company-profile/edit/:id",
              ]}
              layout={Layout}
              component={EditPartnerProfile}
            />
            <PrivateRoute
              exact
              path="/notification"
              layout={Layout}
              component={Notification}
            />
            <PrivateRoute
              path={`${appUrls.registrationReview}:regId`}
              component={RegistrationReview}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/request-mou"
              component={RequestMou}
              layout={Layout}
            />

            <PrivateRoute
              exact
              path="/strategic-indicator"
              component={StrategicIndicator}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/request-mou/offers-and-discounts-agrmnt"
              component={OffersDiscountsAgreement}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/request-mou/offers-and-discounts-agrmnt/:id"
              component={OffersDiscountsAgreement}
              layout={Layout}
            />
            {/* <PrivateRoute
              exact
              path="/request-mou/general-inquiry-agrmnt"
              component={GeneralEnquiry}
              layout={Layout}
            /> */}
            <PrivateRoute
              exact
              path={[
                "/request-mou/partnership/:agreementType",
                "/request-mou/partnership/:agreementType/:agreementId",
              ]}
              component={PartnershipAgreement}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/manage-agreements"
              component={ManageAgreement}
              layout={Layout}
            />

            <PrivateRoute
              exact
              path="/notification-view"
              component={InboxView}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/outsourcing-checklist"
              component={OutsourcingChecklist}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/agreement/:id"
              component={ReviewAgreementRequest}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/agreement/partnership/:id"
              component={ReviewPartnershipAgreementRequest}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/agreement/partner/:id"
              component={ReviewPartnershipAgreementRequestByPartner}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={["/mou-details/:id", "/mou-details/:agreementType/:id"]}
              component={MouDetails}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/agreement/track/:id"
              component={TrackAgreement}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/agreement/partnership/track/:id"
              component={TrackPartnershipAgreement}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path="/agreement/sign/:id"
              component={SignAgreement}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={"/agreement/partnership/sign/:id"}
              component={SignPartnershipAgreement}
              layout={Layout}
            />
            <Route
              exact
              path={"/agreement/partnership/sign/public/:id"}
              component={SignPartnershipAgreementPublic}
            />
            <PrivateRoute
              exact
              path={"/agreement/request-cancel/:type/:id"}
              component={RequestCancelAgreement}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={"/agreement/cancel-review/:type/:id"}
              component={ReviewCancelAgreement}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={"/agreement/renew/:type/:id"}
              component={RenewAgreementRequest}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={"/agreement/supplimentary/:type/:id"}
              component={AmendAgreementRequest}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={"/manage-partners"}
              component={ManagePartners}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={"/manage-users"}
              component={ManageUsers}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={["/add-partner", "/edit-partner/:id"]}
              component={AddEditPartner}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={"/request-consultation"}
              component={RequestCounsel}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={"/counsel/:id"}
              component={ReviewCounsel}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={"/manage-counsels"}
              component={ManageCounsels}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={["/add-user", "/edit-user/:id"]}
              component={AddEditUser}
              layout={Layout}
            />
            <PrivateRoute
              exact
              path={["/report"]}
              component={Report}
              layout={Layout}
            />
            {/* <PrivateRoute
              exact
              path={"/test"}
              component={signingCeremony}
              layout={Layout}
            /> */}
            <Route component={Error} />
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default AppRoutes;
