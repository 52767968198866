import React from "react";
import { useField, Field, ErrorMessage } from "formik";

const TextArea = ({ isArabic, ...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <Field
        {...props}
        as="textarea"
        rows="4"
        className={`form-control ${isArabic ? "ar-text" : ""} ${
          meta.touched && meta.error ? "is-invalid" : ""
        }`}
      />
      <ErrorMessage
        name={props.name}
        component="div"
        className="invalid-feedback"
      />
    </>
  );
};

export default TextArea;
