import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loader = ({ show }) => {
  return (
    show && (
      <div
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          zIndex: 999999999999,
          backgroundColor: "rgb(66 66 66 / 70%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" variant="success" />
      </div>
    )
  );
};

export default Loader;
