import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

import Notification from "../Toast";
import Loader from "../Loader";
import ConnectPopup from "../ConnectPopup";

import "./Layout.scss";

import {
  logoutUser,
  getUserProfile,
  getMyDeptInfo,
  getNotificationCount,
  getNotOpenedNotificationCount,
} from "../../actions/authActions";
import { changeLanguage, setMessage } from "../../actions/generalActions";
import { sendConnect, toggleConnect } from "../../actions/connectActions";

const Layout = (props) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    props.getUserProfile();
    props.getNotificationCount();
    props.getNotOpenedNotificationCount();
  }, []);

  useEffect(() => {
    if (
      props.user?.RoleId === 4 ||
      props.user?.RoleId === 1 ||
      props.user?.RoleId === 5
    )
      props.getMyDeptInfo();
  }, [props.user]);

  return (
    <div className="app">
      <Loader show={props.isLoading} />
      <ConnectPopup
        show={props.isConnectOpen}
        onSubmit={props.sendConnect}
        onHide={() => props.toggleConnect(false)}
      />
      <Notification
        onHide={() => props.setMessage("")}
        message={props.message}
      />
      <Header
        onLogout={props.logoutUser}
        onLanguageChange={props.changeLanguage}
        userDetails={props.user}
        count={props.notification}
        notOpenedCount={props.notOpenedCount}
      />
      <Sidebar
        user={props.user}
        currentPath={props.location.pathname + props.location.search}
      />
      <div className="main-body" style={{ marginTop: "70px" }}>
        <div
          dir={i18n.language === "ar" ? "rtl" : "ltr"}
          className="page-wrapper"
        >
          <main>{props.children}</main>
        </div>
      </div>
      <Footer />

    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  message: state.general.message,
  isLoading: state.general.isLoading,
  user: state.auth.profile,
  isConnectOpen: state.connect.isOpen,
  notification: state.auth.count,
  notOpenedCount: state.auth.notOpenedNotificationCount,
});

const mapDispatchToProps = {
  logoutUser,
  changeLanguage,
  setMessage,
  getUserProfile,
  sendConnect,
  toggleConnect,
  getMyDeptInfo,
  getNotificationCount,
  getNotOpenedNotificationCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
