import {
  SET_CURRENT_USER,
  USER_LOADING,
  RESET_ALL,
  GET_USER_PROFILE,
  GET_MY_DEPT_INFO,
  VALIDATE_RESET_LINK,
  GET_COUNT,
  GET_NOT_OPENED_NOTIFICATION_COUNT,
  UPDATE_OPENED_NOTIFICATION_COUNT,
} from "../config/actionTypes";
import isEmpty from "is-empty";

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  profile: null,
  deptInfo: null,
  isResetLinkValid: false,
  emailToReset: null,
  count: null,
  notOpenedNotificationCount: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case VALIDATE_RESET_LINK:
      return {
        ...state,
        isResetLinkValid: true,
        emailToReset: payload,
      };
    case GET_MY_DEPT_INFO:
      return {
        ...state,
        deptInfo: payload,
      };
    case GET_COUNT:
      return {
        ...state,
        count: payload,
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        profile: payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(payload),
        user: payload,
      };
    case GET_NOT_OPENED_NOTIFICATION_COUNT:
      return {
        ...state,
        notOpenedNotificationCount: payload,
      };
    case UPDATE_OPENED_NOTIFICATION_COUNT:
      return {
        ...state,
        updateAllViewedNotification: payload,
      };

    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
