import React, { useRef } from "react";
import Select from "react-select";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

const CustomSelect = ({ innerRef, onChange, ...props }) => {
  const { i18n, t } = useTranslation();
  const isAr = i18n.language === "ar";
  const [field, meta, helpers] = useField(props.name);
  const { setValue, setTouched, setError } = helpers;
  return (
    <>
      <Select
        {...props}
        isRtl={isAr}
        isLoading={!props.options}
        ref={innerRef}
        onChange={(val) => {
          console.log(val);

          setTouched(true);
          if (onChange) onChange(val);

          if (props.isMulti) setValue(val ? val.map((item) => item.value) : []);
          else setValue(val?.value);
        }}
        styles={{
          control: (styles, { isFocused }) => {
            let borderColor = "#eee";

            if (!!meta.error && meta.touched) borderColor = "var(--danger)";
            else if (isFocused) borderColor = "#01a9ac";

            return {
              ...styles,
              borderRadius: "2px",
              borderColor,
              height: "35px",
              minHeight: "35px",
              "&:hover": {
                borderColor,
              },
              boxShadow: isFocused
                ? "0 0 0 0.2rem rgb(0 104 69 / 16%)"
                : "none",
            };
          },

          valueContainer: (styles) => ({
            ...styles,
            height: "35px",
            padding: "0 6px",
          }),

          input: (styles) => ({
            ...styles,
            margin: "0px",
          }),
          indicatorsContainer: (styles) => ({
            ...styles,
            height: "35px",
          }),

          menu: (styles) => ({
            ...styles,
            zIndex: 9999,
          }),
        }}
      />
      {!!meta.error && meta.touched && (
        <div className="invalid-feedback d-block">{meta.error}</div>
      )}
    </>
  );
};

export default CustomSelect;
