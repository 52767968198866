export default {
  stakeholderAdmin: [
    { label: "Dashboard", subMenu: null, url: "/dashboard" },
    { label: "Create Agreement", subMenu: null, url: "/request-mou" },
    {
      label: "Manage Agreements",
      subMenu: [
        { label: "All Agreements", url: "/manage-agreements" },
        { label: "Under Process", url: "/manage-agreements?status=3" },
        { label: "Signed Agreements", url: "/manage-agreements?status=1" },
        {
          label: "Archived Agreements",
          url: "/manage-agreements?archived=true",
        },
      ],
      url: false,
    },
    { label: "Manage Partners", subMenu: null, url: "/manage-partners" },
    { label: "Manage Users", subMenu: null, url: "/manage-users" },

    {
      label: "Consultation",
      subMenu: [

        { label: "Manage Counsels", subMenu: null, url: "/manage-counsels" },
      ],
      url: false,
    },

    // { label: "Manage Counsels", subMenu: null, url: "/manage-counsels" },

    {
      label: "Meetings",
      subMenu: [
        { label: "My Meetings", subMenu: null, url: "/my-meetings" },
        { label: "Schedule Meeting", subMenu: null, url: "/schedule-meeting" },
      ],
      url: false,
    },

    // { label: "Report", subMenu: null, url: "/report" },
  ],
  partnerAdmin: [
    { label: "Dashboard", subMenu: null, url: "/dashboard" },

    { label: "Request Agreement", subMenu: null, url: "/request-mou" },
    {
      label: "Manage Agreements",
      subMenu: [
        { label: "All Agreements", url: "/manage-agreements" },
        { label: "Under Process", url: "/manage-agreements?status=3" },
        { label: "Signed Agreements", url: "/manage-agreements?status=1" },
        {
          label: "Archived Agreements",
          url: "/manage-agreements?archived=true",
        },
      ],
      url: null,
    },
    {
      label: "Consultation",
      subMenu: [
        {
          label: "Request Consultation",
          subMenu: null,
          url: "/request-consultation",
        },
        { label: "Manage Counsels", subMenu: null, url: "/manage-counsels" },
      ],
      url: false,
    },

    { label: "Manage Counsels", subMenu: null, url: "/manage-counsels" },
    {
      label: "Meetings",
      subMenu: [
        { label: "My Meetings", subMenu: null, url: "/my-meetings" },
        { label: "Schedule Meeting", subMenu: null, url: "/schedule-meeting" },
      ],
      url: false,
    },
  ],

  partnerUser: [
    { label: "Dashboard", subMenu: null, url: "/dashboard" },
    { label: "Request Agreement", subMenu: null, url: "/request-mou" },
    {
      label: "Manage Agreements",
      subMenu: [
        { label: "All Agreements", url: "/manage-agreements" },
        { label: "Under Process", url: "/manage-agreements?status=3" },
        { label: "Signed Agreements", url: "/manage-agreements?status=1" },
        {
          label: "Archived Agreements",
          url: "/manage-agreements?archived=true",
        },
      ],
      url: null,
    },
    {
      label: "Consultation",
      subMenu: [
        {
          label: "Request Consultation",
          subMenu: null,
          url: "/request-consultation",
        },
        { label: "Manage Counsels", subMenu: null, url: "/manage-counsels" },
      ],
      url: false,
    },
    {
      label: "Meetings",
      subMenu: [
        { label: "My Meetings", subMenu: null, url: "/my-meetings" },
        { label: "Schedule Meeting", subMenu: null, url: "/schedule-meeting" },
      ],
      url: false,
    },
  ],

  dedUser: [
    { label: "Dashboard", subMenu: null, url: "/dashboard" },

    { label: "Request Agreement", subMenu: null, url: "/request-mou" },
    {
      label: "Manage Agreements",
      subMenu: [
        { label: "All Agreements", url: "/manage-agreements" },
        { label: "Under Process", url: "/manage-agreements?status=3" },
        { label: "Signed Agreements", url: "/manage-agreements?status=1" },
        {
          label: "Archived Agreements",
          url: "/manage-agreements?archived=true",
        },
      ],
      url: null,
    },

    {
      label: "Consultation",
      subMenu: [
        {
          label: "Request Consultation",
          subMenu: null,
          url: "/request-consultation",
        },
        { label: "Manage Counsels", subMenu: null, url: "/manage-counsels" },
      ],
      url: false,
    },
    {
      label: "Meetings",
      subMenu: [
        { label: "My Meetings", subMenu: null, url: "/my-meetings" },
        { label: "Schedule Meeting", subMenu: null, url: "/schedule-meeting" },
      ],
      url: false,
    },
  ],
};
