import {
  CHANGE_LANGUAGE,
  SET_MESSAGE,
  SET_LOADING,
  GET_AGREEMENTS_TYPES,
  GET_EMIRATES,
  GET_DEPARTMENTS,
  GET_USERS_BY_DEPT,
  GET_USER_ROLES,
  RESET_ALL,
  GET_AGREEMENT_RENEW_TYPES,
  GET_STATS,
  GET_RESOURCE_TYPES,
  GET_AGREEMENT_APPLICATION_TYPES,
  GET_ALL_DET,
  GET_DEPARTMENT_BY_DET
} from "../config/actionTypes";

const initialState = {
  ln: "en",
  message: null,
  isLoading: false,
  agreementTypes: [],
  emirates: null,
  departments: null,
  allDET: null,
  usersInDepartment: null,
  roles: null,
  agreementRenewTypes: null,
  stats: null,
  resourceTypes: null,
  applciationTypes: null,
  departments: null,
  detDepartment: null
};
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AGREEMENT_APPLICATION_TYPES:
      return {
        ...state,
        applciationTypes: action.payload,
      };

    case GET_RESOURCE_TYPES:
      return {
        ...state,
        resourceTypes: action.payload,
      };
    case GET_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case GET_USER_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case GET_USERS_BY_DEPT:
      return {
        ...state,
        usersInDepartment: action.payload,
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case GET_DEPARTMENT_BY_DET:
      return {
        ...state,
        detDepartment: action.payload,
      };
    case GET_ALL_DET:
      return {
        ...state,
        allDET: action.payload,
      };
    case CHANGE_LANGUAGE:
      return { ...state, ln: action.payload };

    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GET_AGREEMENTS_TYPES:
      return {
        ...state,
        agreementTypes: action.payload,
      };

    case GET_EMIRATES:
      return {
        ...state,
        emirates: action.payload,
      };

    case GET_AGREEMENT_RENEW_TYPES:
      return {
        ...state,
        agreementRenewTypes: action.payload,
      };

    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
