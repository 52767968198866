import {
  GET_EXIT_STRATERGIES,
  GET_LIKELYHOOD,
  GET_MOU_DETAILS,
  GET_RISK_TYPE,
  GET_SEVERITY,
  GET_MATRICES,
  GET_PARTNERSHIP_APPROVAL_DETAILS,
  GET_AGREEMENT_OWNERS,
  RESET_ALL,
} from "../config/actionTypes";

const initialState = {
  mouDetails: null,
  riskTypes: null,
  likelihood: null,
  severity: null,
  exitStratergies: null,
  matrices: null,
  approvalDetails: null,
  agreementOwners: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_AGREEMENT_OWNERS:
      return {
        ...state,
        agreementOwners: payload,
      };
    case GET_PARTNERSHIP_APPROVAL_DETAILS:
      return {
        ...state,
        approvalDetails: payload,
      };
    case GET_MATRICES:
      return {
        ...state,
        matrices: payload,
      };
    case GET_EXIT_STRATERGIES:
      return {
        ...state,
        exitStratergies: payload,
      };
    case GET_MOU_DETAILS:
      return {
        ...state,
        mouDetails: payload,
      };

    case GET_RISK_TYPE:
      return {
        ...state,
        riskTypes: payload,
      };

    case GET_LIKELYHOOD:
      return {
        ...state,
        likelihood: payload,
      };

    case GET_SEVERITY:
      return {
        ...state,
        severity: payload,
      };
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
