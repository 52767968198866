export const RESET_ALL = "RESET_ALL";
export const USER_LOGOUT = "USER_LOGOUT";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_LOADING = "SET_LOADING";

export const GET_PARTNER_DROPDOWNS = "GET_PARTNER_DROPDOWNS";
export const REGISTER_PARTNER = "REGISTER_PARTNER";
export const RE_REGISTER_PARTNER = "RE_REGISTER_PARTNER";
export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const GET_OTHER_EMIRATES = "GET_OTHER_EMIRATES";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const VALIDATE_RESET_LINK = "VALIDATE_RESET_LINK";

export const GET_PARTNER_REVIEW = "GET_PARTNER_REVIEW";
export const GET_PARTNER_USERS = "GET_PARTNER_USERS";
export const GET_DET_USERS = "GET_DET_USERS";

export const CLEAR_PARTNER = "CLEAR_PARTNER";
export const UPDATE_PARTNER_REVIEW = "UPDATE_PARTNER_REVIEW";
export const GET_PARTNERS = "GET_PARTNERS";

export const GET_USERS = "GET_USERS";
export const CREATE_USRE = "CREATE_USRE";
export const UPDATE_USRE = "UPDATE_USRE";
export const GET_USERS_BY_ID = "GET_USERS_BY_ID";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_FILTERED_NOTIFICATIONS = "GET_FILTERED_NOTIFICATIONS";
export const SET_NOTIFICATION_VIEW = "SET_NOTIFICATION_VIEW";
export const SET_NOTIFICATION_LOADING = "SET_NOTIFICATION_LOADING";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const GET_AGREEMENTS_TYPES = "GET_AGREEMENTS_TYPES";
export const GET_EMIRATES = "GET_EMIRATES";

export const ADD_DISCOUNTS_AND_OFFERS = "ADD_DISCOUNTS_AND_OFFERS";
export const ADD_DISCOUNTS_AND_OFFERS_OUTLETS =
  "ADD_DISCOUNTS_AND_OFFERS_OUTLETS";
export const REMOVE_DISCOUNTS_AND_OFFERS_OUTLETS =
  "REMOVE_DISCOUNTS_AND_OFFERS_OUTLETS";
export const GET_DISCOUNTS_AND_OFFERS = "GET_DISCOUNTS_AND_OFFERS";
export const SUBMIT_AGREEMENT = "SUBMIT_AGREEMENT";
export const CLEAR_AGREEMENTS_DATA = "CLEAR_AGREEMENTS_DATA";
export const GET_PROCESS_DURATION = "GET_PROCESS_DURATION";

export const GENERATE_AGREEMENT = "GENERATE_AGREEMENT";

export const COMMENT_ON_AGREEMENT = "COMMENT_ON_AGREEMENT";
export const APPROVE_AGREEMENT = "APPROVE_AGREEMENT";
export const GET_AGREEMENT_REVIEW_HISTORY = "GET_AGREEMENT_REVIEW_HISTORY";

export const GET_ALL_AGREEMENTS = "GET_ALL_AGREEMENTS";
export const GET_PARTNER_AGREEMENTS = "GET_PARTNER_AGREEMENTS";

export const GET_AGREEMENT_REVIEW_DETAILS = "GET_AGREEMENT_REVIEW_DETAILS";
export const GET_AGREEMENT_APPROVAL_DETAILS = "GET_AGREEMENT_APPROVAL_DETAILS";

export const GET_DOCUMENT_VERSIONS = "GET_DOCUMENT_VERSIONS";
export const ASSIGN_DOCUMENT = "ASSIGN_DOCUMENT";
export const ASSIGN_IN_DEPT = "ASSIGN_IN_DEPT";
export const UN_ASSIGN_DOCUMENT = "UN_ASSIGN_DOCUMENT";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const SAVE_DOCUMENT = "SAVE_DOCUMENT";

export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_USERS_BY_DEPT = "GET_USERS_BY_DEPT";

export const VERIFY_SIGNING_TOKEN = "VERIFY_SIGNING_TOKEN";
export const PARTNER_SIGN_AGREEMENT = "PARTNER_SIGN_AGREEMENT";
export const STAKEHOLDER_SIGN_AGREEMENT = "STAKEHOLDER_SIGN_AGREEMENT";
export const DED_SIGN_AGREEMENT = "DED_SIGN_AGREEMENT";

export const REQUEST_CANCEL_AGREEMENT = "CANCEL_AGREEMENT";
export const GET_ALL_CANCELLATION_REQUESTS = "GET_ALL_CANCELLATION_REQUESTS";
export const GET_CANCELLATION_REQUEST = "GET_CANCELLATION_REQUEST";
export const LEGAL_CANCELLATION_APPROVAL = "LEGAL_CANCELLATION_APPROVAL";
export const STAKEHOLDER_CANCELLATION_APPROVAL =
  "STAKEHOLDER_CANCELLATION_APPROVAL";
export const STAKEHOLDER_AGREEMENT_CANCELLATION =
  "STAKEHOLDER_AGREEMENT_CANCELLATION";

export const USER_AGREEMENT_CANCELLATION = "USER_AGREEMENT_CANCELLATION";

export const REQUEST_RENEW_AGREEMENT = "REQUEST_RENEW_AGREEMENT";
export const REQUEST_AMEND_AGREEMENT = "REQUEST_AMEND_AGREEMENT";

export const SAVE_PARTNER_PROFILE = "SAVE_PARTNER_PROFILE";
export const SAVE_USER_PROFILE = "SAVE_USER_PROFILE";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const GET_MY_DEPT_INFO = "GET_MY_DEPT_INFO";

export const GET_USER_ROLES = "GET_USER_ROLES";
export const ADD_PARTNER_USER = "ADD_PARTNER_USER";

export const SEND_CONNECT = "SEND_CONNECT";
export const OPEN_CONNECT = "OPEN_CONNECT";
export const CLOSE_CONNECT = "CLOSE_CONNECT";
export const GET_SENT_MESSAGES = "GET_SENT_MESSAGES";
export const GET_INBOX_MESSAGES = "GET_INBOX_MESSAGES";

export const ADD_PARTNER = "ADD_PARTNER";
export const INVITE_PARTNER = "INVITE_PARTNER";
export const GET_PARTNER_CLASSIFICATION = "GET_PARTNER_CLASSIFICATION";
export const GET_AGREEMENT_LANGUAGE = "GET_AGREEMENT_LANGUAGE";

export const GET_PARTNERSHIP_DETAILS = "GET_PARTNERSHIP_DETAILS";
export const GET_STRATEGIC_INDICATOR = "GET_STRATEGIC_INDICATOR";
export const GET_SELECTED_STRATEGIC_INDICATOR =
  "GET_SELECTED_STRATEGIC_INDICATOR";
export const GET_SELECTION_CRITERIA = "GET_SELECTION_CRITERIA";
export const GET_AGREEMENT_RENEW_TYPES = "GET_AGREEMENT_RENEW_TYPES";
export const SAVE_PARTNERSHIP_DETAILS = "SAVE_PARTNERSHIP_DETAILS";
export const SAVE_ADDITIONAL_PARTNERSHIP_DETAILS =
  "SAVE_ADDITIONAL_PARTNERSHIP_DETAILS";
export const CLEAR_PARTNERSHIP_DETAILS = "CLEAR_PARTNERSHIP_DETAILS";

export const GET_RESOURCE_TYPES = "GET_RESOURCE_TYPES";
export const ADD_RESOURCE = "ADD_RESOURCE";
export const REMOVE_RESOURCE = "REMOVE_RESOURCE";

export const SAVE_SELECTION_CRITERIA = "SAVE_SELECTION_CRITERIA";
export const ASSIGN_TO_CONTACT = "ASSIGN_TO_CONTACT";
export const ADD_CONTACT = "ADD_CONTACT";
export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const SET_DEFAULT_CONTACT = "SET_DEFAULT_CONTACT";

export const GET_AGREMENT_FLOW = "GET_AGREMENT_FLOW";

export const GET_MOU_DETAILS = "GET_MOU_DETAILS";
export const SAVE_MOU_RESOURCE_PLANNING = "SAVE_MOU_RESOURCE_PLANNING";

export const GET_RISK_TYPE = "GET_RISK_TYPE";
export const GET_LIKELYHOOD = "GET_LIKELYHOOD";
export const GET_SEVERITY = "GET_SEVERITY";

export const ADD_RISK_ASSESSMENT = "ADD_RISK_ASSESSMENT";
export const ADD_PERFORMANCE_MEASUREMENT = "ADD_PERFORMANCE_MEASUREMENT";

export const REMOVE_RISK_ASSESSMENT = "REMOVE_RISK_ASSESSMENT";
export const REMOVE_PERFORMANCE_MEASUREMENT = "REMOVE_PERFORMANCE_MEASUREMENT";

export const GET_EXIT_STRATERGIES = "GET_EXIT_STRATERGIES";
export const SAVE_EXIT_STRATERGIES = "SAVE_EXIT_STRATERGIES";

export const GET_MATRICES = "GET_MATRICES";
export const SUBMIT_MAPPING = "SUBMIT_MAPPING";

export const SAVE_PARTNER_OBLIGATION = "SAVE_PARTNER_OBLIGATION";
export const PARTER_PARTNERSHIP_APPROVAL = "PARTER_PARTNERSHIP_APPROVAL";

export const GET_PARTNERSHIP_APPROVAL_DETAILS =
  "GET_PARTNERSHIP_APPROVAL_DETAILS";

export const GET_AGREEMENT_INFO = "GET_AGREEMENT_INFO";
export const GET_STATS = "GET_STATS";

export const GET_AGREEMENT_OWNERS = "GET_AGREEMENT_OWNERS";

export const ARCHIVE_AGREEMENT = "ARCHIVE_AGREEMENT";
export const UN_ARCHIVE_AGREEMENT = "UN_ARCHIVE_AGREEMENT";

export const HOLD_AGREEMENT = "HOLD_AGREEMENT";
export const UN_HOLD_AGREEMENT = "UN_HOLD_AGREEMENT";

export const ADD_SECONDARY_PARTY = "ADD_SECONDARY_PARTY";
export const ASSIGN_SECONDARY_PARTY = "ASSIGN_SECONDARY_PARTY";
export const REMOVE_SECONDARY_PARTY = "REMOVE_SECONDARY_PARTY";

export const COMMENT_ON_SECTION = "COMMENT_ON_SECTION";
export const UPDATE_MENTION_VIEW = "UPDATE_MENTION_VIEW";
export const GET_DOCUMENT_CHANGE_HISTORY = "GET_DOCUMENT_CHANGE_HISTORY";

export const GET_AGREEMENT_ACTIVITIES = "GET_AGREEMENT_ACTIVITIES";

export const GET_AGREEMENT_APPLICATION_TYPES =
  "GET_AGREEMENT_APPLICATION_TYPES";

export const UPLOAD_DOCUMENT_BY_STAKEHOLDER = "UPLOAD_DOCUMENT_BY_STAKEHOLDER";

export const REQUEST_FOR_COUNSEL = "REQUEST_FOR_COUNSEL";
export const GET_COUNSEL_BY_ID = "GET_COUNSEL_BY_ID";
export const REVIEW_COUNCEL = "REVIEW_COUNCEL";
export const REPLY_COUNSEL = "REPLY_COUNSEL";
export const GET_COUNSELS_BY_USER = "GET_COUNSELS_BY_USER";
export const GET_ALL_COUNSELS = "GET_ALL_COUNSELS";

export const GET_AGREEMENT_USERS = "GET_AGREEMENT_USERS";
export const GET_RELEVANT_DEPT_USERS = "GET_RELEVANT_DEPT_USERS";
export const CREATE_MEETING = "CREATE_MEETING";
export const RESHEDULE_MEETING = "RESHEDULE_MEETING";
export const CREATE_CEREMONY = "CREATE_CEREMONY";
export const GET_MEETINGS_FOR_USER = "GET_MEETINGS_FOR_USER";
export const GET_MEETING_BY_ID = "GET_MEETING_BY_ID";
export const GET_MEETINGS_BY_AGREEMENT = "GET_MEETINGS_BY_AGREEMENT";
export const GET_MEETINGS_BY_AGREEMENT_FOR_USER =
  "GET_MEETINGS_BY_AGREEMENT_FOR_USER";
export const UPDATE_MEETING_MINUTES = "UPDATE_MEETING_MINUTES";
export const UPDATE_MEETING_USER_RESPONSE = "UPDATE_MEETING_USER_RESPONSE";

export const UPLOAD_FILE = "UPLOAD_FILE";

export const GET_REPORT_INFO = "GET_REPORT_INFO";
export const GET_TYPES = "GET_TYPES";
export const GET_PARTNER_INFO = "GET_PARTNER_INFO";
export const GET_SIGNING_CEREMONY = "GET_SIGNING_CEREMONY";
export const GET_MEDIAS = "GET_MEDIAS";
export const GET_PONTS_OF_CONTACTS = "GET_PONTS_OF_CONTACTS";
export const UNLOCK_USERS = "UNLOCK_USERS";
export const REMOVE_MEDIA = "REMOVE_MEDIA";
export const GET_COUNT = "GET_COUNT";
export const CANCEL_MEETING = "CANCEL_MEETING";
export const GET_ALL_DET = "GET_ALL_DET";
export const GET_DEPARTMENT_BY_DET = "GET_DEPARTMENT_BY_DET";
export const GET_NOT_OPENED_NOTIFICATION_COUNT =
  "GET_NOT_OPENED_NOTIFICATION_COUNT";
export const UPDATE_OPENED_NOTIFICATION_COUNT =
  "UPDATE_OPENED_NOTIFICATION_COUNT";

export const GET_CONNECT_USERS = "GET_CONNECT_USERS";
export const GET_MEETINGS_BY_DATE_RANGE = "GET_MEETINGS_BY_DATE_RANGE";
export const GET_CHANGES_HISTORY_BY_VERSION = "GET_CHANGES_HISTORY_BY_VERSION";
