import axios from "axios";
import store from "../store";

import { setMessage } from "../actions/generalActions";
import { logoutUser } from "../actions/authActions";
import isEmpty from "is-empty";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
});

export default api;

// Add a request interceptor
api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Unauthorized
    if (error.response?.status === 401) {
      store.dispatch(setMessage(error.response.data.Message));
      setTimeout(() => {
        store.dispatch(logoutUser());
      }, 3000);
    }

    // Server error
    if (error.response?.status === 500) {
      store.dispatch(setMessage("Something went wrong. Please try again."));
    }

    if (
      error.response?.status === 400 &&
      !isEmpty(error?.response?.data?.Message)
    ) {
      store.dispatch(setMessage(error?.response?.data?.Message));
    }

    return Promise.reject(error);
  }
);
