import React, { useEffect, useState } from "react";
import {
  User,
  LogOut,
  Mail,
  Inbox,
  Home,
  Briefcase,
  Bell,
} from "react-feather";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";

import adminLogo from "../../assets/images/logo-admin.png";
import avatar from "../../assets/images/avatar.png";

const Header = (props) => {
  const { i18n } = useTranslation();

  const [profileImage, setProfileImage] = useState(avatar);

  useEffect(() => {
    if (props.userDetails?.ProfilePicture)
      setProfileImage(
        process.env.REACT_APP_BASE_URL + props.userDetails.ProfilePicture
      );
    else if (props.userDetails?.CompanyLogo)
      setProfileImage(
        process.env.REACT_APP_BASE_URL + props.userDetails.CompanyLogo
      );
  }, [props.userDetails]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    props.onLanguageChange(lng);
    window.location.reload();
  };
  // useEffect(() => {
  //   props.getNotOpenedNotificationCount();
  // }, []);

  return (
    <div
      id="pcoded"
      className="pcoded iscollapsed"
      theme-layout="vertical"
      vertical-placement={i18n.language === "ar" ? "right" : "left"}
      vertical-layout="wide"
      pcoded-device-type="desktop"
      vertical-nav-type="expanded"
      vertical-effect="shrink"
      vnavigation-view="view1"
      nav-type="st6"
      fream-type="theme1"
      sidebar-img="false"
      sidebar-img-type="img1"
      layout-type="light"
    >
      {/* <div className="pcoded-overlay-box"></div> */}
      <div className="pcoded-container navbar-wrapper">
        <nav
          className="navbar header-navbar pcoded-header iscollapsed"
          header-theme="theme4"
          pcoded-header-position="fixed"
        >
          <div className="navbar-wrapper d-flex align-items-center">
            <div className="navbar-logo" logo-theme="theme4">
              <a style={{ height: "100%" }}>
                <img
                  className="img-fluid"
                  style={{ maxHeight: "100%" }}
                  src={adminLogo}
                  alt="DET Smart Stakeholders System"
                />
              </a>
            </div>

            <div className="col-auto col-lg navbar-container container-fluid d-inline-block p-0 ml-auto">
              <ul className="nav-right d-block">
                <li>
                  <Link to="/notification" className="">
                    <Bell size={21} />
                    {props.notOpenedCount ? (
                      <div className="notViewCount">{props.notOpenedCount}</div>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>

                <li>
                  <Link to="/connect/inbox" className="">
                    <FaRegEnvelope size={21} />{" "}
                    {props.count ? (
                      <div className="notViewCount">{props.count}</div>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>

                <li className="user-profile header-notification text-nowrap">
                  <div className="dropdown-primary dropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        as="div"
                        align="down"
                        id="dropdown-basic"
                        className="dropdown-toggle"
                      >
                        <img
                          src={profileImage}
                          className="img-radius"
                          alt="User-Profile-Image"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        as="ul"
                        className="show-notification profile-notification dropdown-menu py-0"
                      >
                        <Dropdown.Item
                          to="/profile"
                          as={Link}
                          className="text-primary border-bottom py-3"
                        >
                          <Mail size={14} className="mr-2" />
                          {props.userDetails?.Email}
                        </Dropdown.Item>

                        <Dropdown.Item
                          to="/profile"
                          as={Link}
                          className="menuHvr d-flex align-items-center"
                        >
                          <User size={14} className="mr-2" /> Profile
                        </Dropdown.Item>

                        <Dropdown.Item
                          to="/my-company-profile"
                          as={Link}
                          className="menuHvr d-flex align-items-center"
                        >
                          <Briefcase size={14} className="mr-2" /> Company
                          Profile
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={props.onLogout}
                          className="menuHvr d-flex align-items-center"
                        >
                          <LogOut size={14} className="mr-2" /> Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </li>

                {/* <li className="user-profile header-notification text-nowrap multilng">
                  <strong
                    className={i18n.language !== "ar" ? "active" : ""}
                    onClick={() => changeLanguage("en")}
                  >
                    ENGLISH
                  </strong>
                  |
                  <strong
                    className={i18n.language === "ar" ? "active" : ""}
                    onClick={() => changeLanguage("ar")}
                  >
                    عربي
                  </strong>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
