import React from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";

import i18n from "./i18n";
import setAuthInHeaders from "./utils/setAuthToken";
import { getUser } from "./utils/storage";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import store from "./store";
import AppRoutes from "./AppRoutes";

import "font-awesome/css/font-awesome.min.css";
import DirectionStyles from "./components/DirectionStyles";

import HttpsRedirect from "react-https-redirect";

if (getUser()) {
  // Set auth token header auth
  const data = getUser();

  if (data) {
    setAuthInHeaders(data);

    store.dispatch(setCurrentUser(data));
  } else {
    store.dispatch(logoutUser());
  }
}

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <HttpsRedirect>
          <DirectionStyles />
          <AppRoutes />
        </HttpsRedirect>
      </Provider>
    </I18nextProvider>
  );
}

export default App;
