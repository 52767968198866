import React from "react";
import "./index.css";

const Alert = (props) => {
  return (
    <div className={`alert icons-alert ${props.type}`}>
      <button
        onClick={props.onClose}
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        {props.onClose && (
          <i className="icofont icofont-close-line-circled"></i>
        )}
      </button>
      <p>{props.message}</p>
    </div>
  );
};

export default Alert;
