import React from "react";

const Footer = () => {
  return (
    <div className="footer text-center shadow">
      © Department of Economy and Tourism. {new Date().getFullYear()}. All
      rights reserved.
    </div>
  );
};

export default Footer;
