import React from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import Textfield from "../components/Form/Textfield";
import Textarea from "../components/Form/TextArea";
import Autocomplete from "../components/Form/AsyncAutocomplete";

import { getConnectUsers } from "../actions/connectActions";

const ConnectPopup = (props) => {
  const { i18n, t } = useTranslation();
  const isAr = i18n.language === "ar";

  const searchOptions = async (val) => {
    let res = [];

    if (val.length > 0)
      res = await getConnectUsers({ q: val, code: isAr ? "ar" : "en" });
    return res.map((d) => ({
      value: d.Id,
      label: `${d.Email} ${
        d.FirstName ? `(${d.FirstName} ${d.LastName})` : ""
      }`,
    }));
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="send-message"
    >
      <Modal.Header closeButton>
        <h5 className="modal-title">Connect / Send Comments</h5>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={yup.object().shape({
            Subject: yup.string().required().label("Subject"),
            Message: yup.string().required().label("Message"),
            SentTo: yup.string().required().label("To"),
          })}
          initialValues={{
            Subject: "",
            Message: "",
            SentTo: "",
          }}
          onSubmit={props.onSubmit}
        >
          {(props) => (
            <Form>
              <div className="form-group">
                <label>To:</label>
                <Autocomplete
                  loadOptions={searchOptions}
                  name="SentTo"
                  onChange={(val) => props.setFieldValue("SentTo", val.value)}
                  onBlur={() => props.setFieldTouched("SentTo", true)}
                  error={props.errors.SentTo}
                  touched={props.touched.SentTo}
                  placeholder="Type to search..."
                />
              </div>
              <div className="form-group">
                <label>Subject:</label>
                <Textfield name="Subject" />
              </div>
              <div className="form-group">
                <label>Message:</label>
                <Textarea name="Message" />
              </div>
              <button type="submit" className="btn btn-blue-green px-5">
                Send
              </button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ConnectPopup;
