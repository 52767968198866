import {
  GET_DISCOUNTS_AND_OFFERS,
  CLEAR_AGREEMENTS_DATA,
  GET_ALL_AGREEMENTS,
  GET_PROCESS_DURATION,
  GET_AGREEMENT_APPROVAL_DETAILS,
  GET_AGREEMENT_REVIEW_DETAILS,
  GET_PARTNERSHIP_DETAILS,
  GET_STRATEGIC_INDICATOR,
  GET_SELECTED_STRATEGIC_INDICATOR,
  GET_SELECTION_CRITERIA,
  GET_AGREEMENT_LANGUAGE,
  GET_AGREMENT_FLOW,
  GET_AGREEMENT_INFO,
  CLEAR_PARTNERSHIP_DETAILS,
  RESET_ALL,
  VERIFY_SIGNING_TOKEN,
  GET_ALL_CANCELLATION_REQUESTS,
  GET_CANCELLATION_REQUEST,
  GET_AGREEMENT_REVIEW_HISTORY,
  GET_AGREEMENT_ACTIVITIES,
  GET_SIGNING_CEREMONY,
  GET_MEDIAS,
} from "../config/actionTypes";

const initialState = {
  discountsAndOffersData: null,
  allAgreements: null,
  processDuration: null,
  approvalDetails: null,
  reviewDetails: null,
  partnershipDetailsData: null,
  selectionCriteria: null,
  agreementLanguage: null,
  agreementFlow: null,
  agreementInfo: null,
  isSigningTokenValid: false,
  cancellationRequests: null,
  chosenCancellation: null,
  reviewHistory: null,
  agreementActivities: null,
  signingData: null,
  medias: [],
  strategicIndictor: null,
  selectedStrategicIndictor: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_AGREEMENT_ACTIVITIES:
      return {
        ...state,
        agreementActivities: payload.slice().reverse(),
      };
    case GET_AGREEMENT_REVIEW_HISTORY:
      return {
        ...state,
        reviewHistory: payload,
      };
    case GET_CANCELLATION_REQUEST:
      return {
        ...state,
        chosenCancellation: payload,
      };
    case GET_ALL_CANCELLATION_REQUESTS:
      return {
        ...state,
        cancellationRequests: payload,
      };
    case CLEAR_PARTNERSHIP_DETAILS:
      return {
        ...state,
        partnershipDetailsData: null,
      };
    case VERIFY_SIGNING_TOKEN:
      return {
        ...state,
        isSigningTokenValid: true,
      };
    case GET_AGREEMENT_INFO:
      return {
        ...state,
        agreementInfo: payload,
      };
    case GET_AGREMENT_FLOW:
      return {
        ...state,
        agreementFlow: payload,
      };
    case GET_AGREEMENT_LANGUAGE:
      return {
        ...state,
        agreementLanguage: payload,
      };
    case GET_SELECTION_CRITERIA:
      return {
        ...state,
        selectionCriteria: payload,
      };
    case GET_AGREEMENT_REVIEW_DETAILS:
      return {
        ...state,
        reviewDetails: payload,
      };
    case GET_AGREEMENT_APPROVAL_DETAILS:
      return {
        ...state,
        approvalDetails: payload,
      };
    case GET_PROCESS_DURATION:
      return {
        ...state,
        processDuration: payload,
      };
    case GET_ALL_AGREEMENTS:
      return {
        ...state,
        allAgreements: payload,
      };
    case GET_DISCOUNTS_AND_OFFERS:
      return {
        ...state,
        discountsAndOffersData: payload,
      };
    case CLEAR_AGREEMENTS_DATA:
      return initialState;

    case GET_PARTNERSHIP_DETAILS:
      return {
        ...state,
        partnershipDetailsData: payload,
      };

    case GET_STRATEGIC_INDICATOR:
      return {
        ...state,
        strategicIndictor: payload,
      };

    case GET_SELECTED_STRATEGIC_INDICATOR:
      return {
        ...state,
        selectedStrategicIndictor: payload,
      };

    case GET_SIGNING_CEREMONY:
      return {
        ...state,
        signingData: payload,
      };
    case GET_MEDIAS:
      return {
        ...state,
        medias: payload,
      };

    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
