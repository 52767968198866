import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Table from "react-bootstrap/Table";
import SingleAccordion from "../../components/SingleAccordion";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { Col, Row } from "react-bootstrap";
import { BarCode } from "../barcode/barCode";
import api from "../../utils/api";
import apiUrls from "../../config/apiUrls";
import "./style.scss";

const StrategicIndicator = (props) => {

  const [peopleInfo, setPeopleInfo] = useState([]);
  const [item1, setItem1] = useState([]);
  const [item2, setItem2] = useState([]);
  const [item3, setItem3] = useState([]);
  const [item4, setItem4] = useState([]);
  const [item5, setItem5] = useState([]);
  const [item6, setItem6] = useState([]);
  const [item7, setItem7] = useState([]);
  const [item8, setItem8] = useState([]);
  const [item9, setItem9] = useState([]);
  const [item10, setItem10] = useState([]);
  const [item11, setItem11] = useState([]);
  const [item12, setItem12] = useState([]);
  const [item13, setItem13] = useState([]);
  const [item14, setItem14] = useState([]);
  const [item15, setItem15] = useState([]);
  const [item16, setItem16] = useState([]);
  const [item17, setItem17] = useState([]);
  const [item18, setItem18] = useState([]);
  const [item19, setItem19] = useState([]);
  const [item20, setItem20] = useState([]);
  const [item21, setItem21] = useState([]);
  const [item22, setItem22] = useState([]);

  useEffect(() => {


    if (props.startegicIndicators !== null) {
      props.startegicIndicators.map((data) => {



        if (data.Id === 1) {
          setItem1(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 2) {
          setItem2(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 3) {
          setItem3(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 4) {
          setItem4(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 5) {
          setItem5(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 6) {
          setItem6(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 7) {
          setItem7(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 8) {
          setItem8(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 9) {
          setItem9(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 10) {
          setItem10(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 11) {
          setItem11(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 12) {
          setItem12(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 13) {
          setItem13(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 14) {
          setItem14(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 15) {
          setItem15(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 16) {
          setItem16(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 17) {
          setItem17(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 18) {
          setItem18(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 19) {
          setItem19(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 20) {
          setItem20(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 21) {
          setItem21(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }
        if (data.Id === 22) {
          setItem22(
            data.KPIs.filter(function (el) {
              return el.IsSelected === true;
            })
          );
        }








      });
    }

  }, [props.startegicIndicators]);



  function onChange(event, item, dataId) {


    if (event.target.checked && item.IsSelected && item.SIId === 1) {
      setItem1((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 1) {
      item1.push(item);
    }

    if (event.target.checked && item.IsSelected && item.SIId === 2) {
      setItem2((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 2) {
      item2.push(item);
    }

    if (event.target.checked && item.IsSelected && item.SIId === 3) {
      setItem3((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 3) {
      item3.push(item);
    }
    if (event.target.checked && item.IsSelected && item.SIId === 4) {
      setItem4((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 4) {
      item4.push(item);
    }
    if (event.target.checked && item.IsSelected && item.SIId === 5) {
      setItem5((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 5) {
      item5.push(item);
    }
    if (event.target.checked && item.IsSelected && item.SIId === 6) {
      setItem6((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 6) {
      item6.push(item);
    }
    if (event.target.checked && item.IsSelected && item.SIId === 7) {
      setItem7((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 7) {
      item7.push(item);
    }

    if (event.target.checked && item.IsSelected && item.SIId === 8) {
      setItem8((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 8) {
      item8.push(item);
    }

    if (event.target.checked && item.IsSelected && item.SIId === 9) {
      setItem9((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 9) {
      item9.push(item);
    } if (event.target.checked && item.IsSelected && item.SIId === 10) {
      setItem10((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 10) {
      item10.push(item);
    }
    if (event.target.checked && item.IsSelected && item.SIId === 11) {
      setItem11((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 11) {
      item11.push(item);
    }
    if (event.target.checked && item.IsSelected && item.SIId === 12) {
      setItem12((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 12) {
      item12.push(item);
    }
    if (event.target.checked && item.IsSelected && item.SIId === 13) {
      setItem13((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 13) {
      item13.push(item);
    }

    if (event.target.checked && item.IsSelected && item.SIId === 14) {
      setItem14((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 14) {
      item14.push(item);
    }

    if (event.target.checked && item.IsSelected && item.SIId === 15) {
      setItem15((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 15) {
      item15.push(item);
    }




    if (event.target.checked && item.IsSelected && item.SIId === 16) {
      setItem16((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 16) {
      item16.push(item);
    }


    if (event.target.checked && item.IsSelected && item.SIId === 17) {
      setItem17((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 17) {
      item17.push(item);
    }


    if (event.target.checked && item.IsSelected && item.SIId === 18) {
      setItem18((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 18) {
      item18.push(item);
    }


    if (event.target.checked && item.IsSelected && item.SIId === 19) {
      setItem19((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 19) {
      item19.push(item);
    }


    if (event.target.checked && item.IsSelected && item.SIId === 20) {
      setItem20((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 20) {
      item20.push(item);
    }


    if (event.target.checked && item.IsSelected && item.SIId === 21) {
      setItem21((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 21) {
      item21.push(item);
    }


    if (event.target.checked && item.IsSelected && item.SIId === 22) {
      setItem22((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && item.IsSelected && item.SIId === 22) {
      item22.push(item);
    }



    //_________________________22




    if (event.target.checked && !item.IsSelected && item.SIId === 1) {
      item1.push(item);
    }

    if (event.target.checked && !item.IsSelected && item.SIId === 2) {
      item2.push(item);
    }
    if (event.target.checked && !item.IsSelected && item.SIId === 3) {
      item3.push(item);
    }

    if (event.target.checked && !item.IsSelected && item.SIId === 4) {
      item4.push(item);
    }
    if (event.target.checked && !item.IsSelected && item.SIId === 5) {
      item5.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 6) {
      item6.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 7) {
      item7.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 8) {
      item8.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 9) {
      item9.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 10) {
      item10.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 11) {
      item11.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 12) {
      item12.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 13) {
      item13.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 14) {
      item14.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 15) {
      item15.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 16) {
      item16.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 17) {
      item17.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 18) {
      item18.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 19) {
      item19.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 20) {
      item20.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 21) {
      item21.push(item);
    } if (event.target.checked && !item.IsSelected && item.SIId === 22) {
      item22.push(item);
    }



    //___________________________33



    if (!event.target.checked && !item.IsSelected && item.SIId === 1) {
      setItem1((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && !item.IsSelected && item.SIId === 2) {
      setItem2((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }
    if (!event.target.checked && !item.IsSelected && item.SIId === 3) {
      setItem3((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }

    if (!event.target.checked && !item.IsSelected && item.SIId === 4) {
      setItem4((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    }
    if (!event.target.checked && !item.IsSelected && item.SIId === 5) {
      setItem5((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 6) {
      setItem6((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 7) {
      setItem7((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 8) {
      setItem8((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 9) {
      setItem9((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 10) {
      setItem10((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 11) {
      setItem11((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 12) {
      setItem12((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 13) {
      setItem13((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 14) {
      setItem14((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 15) {
      setItem15((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 16) {
      setItem16((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 17) {
      setItem17((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 18) {
      setItem18((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 19) {
      setItem19((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 20) {
      setItem20((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 21) {
      setItem21((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );
    } if (!event.target.checked && !item.IsSelected && item.SIId === 22) {
      setItem22((prev) =>
        prev.filter((currItem) => currItem.Id !== item.Id)
      );

    }


  }
  const handleStarteegicSubmit = async (values) => {
    const maps = new Map(peopleInfo.map((s) => [s.Id, s]));
    const result = props.startegicIndicators.filter((f) => !maps.get(f.Id));
    let combinedNums = peopleInfo.concat(result);
    const results = Array.from(new Set(combinedNums.map((s) => s))).map(
      (lab) => {
        return {
          Id: lab.Id,
          Name: lab.Name,
          KPIs: combinedNums
            .filter((s) => s.Id === lab.Id)
            .map((edition) => edition.KPIs),
        };
      }
    );
    const res = api.put(
      `${apiUrls.updateStartegicIndicator}?Code=${props.agreementId}`,
      results
    );
    res.then((result) =>
      result.status == 204
        ? props.history.push(
          `/request-mou/partnership/${props.agreementType}/${props.agreementId}?step=4`
        )
        : ""
    );
  };




  const handleStarteegicSubmitq = async (values) => {
    const idd = peopleInfo.map((isItem) => isItem.Id);
    const datas = props.startegicIndicators.map((item) => ({
      Id: item.Id,
      Name: item.Name,
      KPIs: item.KPIs.map((kpi) => ({
        Id: kpi.Id,
        Name: kpi.Name,
        Objective: kpi.Objective,
        IsSelected: idd.includes(kpi.Id) ? true : false,
      })),
    }));
    const res = api.put(
      `${apiUrls.updateStartegicIndicator}?Code=${props.agreementId}`,
      datas
    );
    res.then((result) =>
      result.status == 204
        ? props.history.push(
          `/request-mou/partnership/${props.agreementType}/${props.agreementId}?step=4`
        )
        : ""
    );
  };















  return (
    <>


      <div className="mt-2 mb-3">
        <>
          <Formik
            initialValues={{}}
            onSubmit={handleStarteegicSubmit}
            enableReinitialize
          >
            {({ touched, errors, values, setFieldValue, handleChange }) => (
              <Form>
                {props.startegicIndicators?.map((data, i) => (
                  <SingleAccordion
                    title={
                      <span>
                        {data.Name}
                        {i === 0 ? (
                          <span className="count">
                            {item1.length != 0 ? <span>({item1.length})</span> : ""}
                          </span>
                        ) : i === 1 ? (
                          <span className="count">
                            {item2.length != 0 ? <span>({item2.length})</span> : ""}
                          </span>
                        ) : i === 2 ? (
                          <span className="count">
                            <span>{item3.length != 0 ? <span>({item3.length})</span> : ""}</span>
                          </span>
                        ) : i === 3 ? (
                          <span className="count">
                            <span>{item4.length != 0 ? <span>({item4.length})</span> : ""}</span>
                          </span>
                        ) : i === 4 ? (
                          <span className="count">
                            <span>{item5.length != 0 ? <span>({item5.length})</span> : ""}</span>
                          </span>
                        ) : i === 5 ? (
                          <span className="count">
                            <span>{item6.length != 0 ? <span>({item6.length})</span> : ""}</span>
                          </span>
                        ) : i === 6 ? (
                          <span className="count">
                            <span>{item7.length != 0 ? <span>({item7.length})</span> : ""}</span>
                          </span>
                        ) : i === 7 ? (
                          <span className="count">
                            <span>{item8.length != 0 ? <span>({item8.length})</span> : ""}</span>
                          </span>
                        ) : i === 8 ? (
                          <span className="count">
                            <span>{item9.length != 0 ? <span>({item9.length})</span> : ""}</span>
                          </span>
                        ) : i === 9 ? (
                          <span className="count">
                            <span>{item10.length != 0 ? <span>({item10.length})</span> : ""}</span>
                          </span>
                        ) : i === 10 ? (
                          <span className="count">
                            <span>{item11.length != 0 ? <span>({item11.length})</span> : ""}</span>
                          </span>
                        ) : i === 11 ? (
                          <span className="count">
                            <span>{item12.length != 0 ? <span>({item12.length})</span> : ""}</span>
                          </span>
                        ) : i === 12 ? (
                          <span className="count">
                            <span>{item13.length != 0 ? <span>({item13.length})</span> : ""}</span>
                          </span>
                        ) : i === 13 ? (
                          <span className="count">
                            <span>{item14.length != 0 ? <span>({item14.length})</span> : ""}</span>
                          </span>
                        ) : i === 14 ? (
                          <span className="count">
                            <span>{item15.length != 0 ? <span>({item15.length})</span> : ""}</span>
                          </span>
                        ) : i === 15 ? (
                          <span className="count">
                            <span>{item16.length != 0 ? <span>({item16.length})</span> : ""}</span>
                          </span>
                        ) : i === 16 ? (
                          <span className="count">
                            {item17.length != 0 ? <span>({item17.length})</span> : ""}
                          </span>
                        ) : i === 17 ? (
                          <span className="count">
                            <span>{item18.length != 0 ? <span>({item18.length})</span> : ""}</span>
                          </span>
                        ) : i === 18 ? (
                          <span className="count">
                            <span>{item19.length != 0 ? <span>({item19.length})</span> : ""}</span>
                          </span>
                        ) : i === 19 ? (
                          <span className="count">
                            <span>{item20.length != 0 ? <span>({item20.length})</span> : ""}</span>
                          </span>
                        ) : i === 20 ? (
                          <span className="count">
                            <span>{item21.length != 0 ? <span>({item21.length})</span> : ""}</span>
                          </span>
                        ) : i === 21 ? (
                          <span className="count">
                            <span>{item22.length != 0 ? <span>({item22.length})</span> : ""}</span>
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    }
                  >
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>KPIs name</th>
                          <th>Objective(process)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.KPIs?.map((kpi, i) => (
                          <tr key={i}>
                            <td className="p26">





                              <div
                                className={
                                  kpi.IsSelected ? "active" : "inactive"
                                }
                              >
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="KPI"
                                  name="KPI"
                                  id={kpi.Id}
                                  onChange={(e) => {
                                    onChange(e, kpi);

                                    if (
                                      kpi.IsSelected === true &&
                                      data.Id === kpi.SIId
                                    ) {
                                      setPeopleInfo([
                                        ...peopleInfo,
                                        {
                                          Id: data.Id,
                                          Name: data.Name,
                                          KPIs: {
                                            Id: kpi.Id,
                                            Name: kpi.Name,
                                            Objective: kpi.Objective,
                                            IsSelected: false,
                                            SIId: kpi.SIId,
                                          },
                                        },
                                      ]);
                                    } else if (
                                      kpi.IsSelected === false &&
                                      data.Id === kpi.SIId
                                    ) {
                                      setPeopleInfo([
                                        ...peopleInfo,
                                        {
                                          Id: data.Id,
                                          Name: data.Name,
                                          KPIs: {
                                            Id: kpi.Id,
                                            Name: kpi.Name,
                                            Objective: kpi.Objective,
                                            IsSelected: true,
                                            SIId: kpi.SIId,
                                          },
                                        },
                                      ]);
                                    } else {
                                      setPeopleInfo([
                                        ...peopleInfo,
                                        {
                                          Id: data.Id,
                                          Name: data.Name,
                                          KPIs: {
                                            Id: kpi.Id,
                                            Name: kpi.Name,
                                            Objective: kpi.Objective,
                                            IsSelected: kpi.IsSelected,
                                            SIId: kpi.SIId,
                                          },
                                        },
                                      ]);
                                    }
                                  }}
                                />
                                <label
                                  class="form-check-label pl-2"
                                  for={kpi.Id}>
                                  {kpi.Name}
                                </label>
                              </div>
                            </td>
                            <td className="p26">{kpi.Objective}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </SingleAccordion>
                ))}
                <hr />


                <div className="row my-3 justify-content-center">
                  <div className="col col-sm-auto">
                    <button
                      onClick={props.onPrevious}
                      type="button"
                      className="btn btn-secondary btn-block px-3 px-sm-5"
                    >
                      Previous
                    </button>
                  </div>
                  <div className="col col-sm-auto">
                    <button
                      type="submit"
                      className="btn btn-blue-green btn-block px-3 px-sm-5"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      </div>
    </>
  );
};

export default StrategicIndicator;
