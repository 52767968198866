import { connect } from "react-redux";

import SigningCeremony from "./SigningCeremony";
import {
  getAgreementUsers,
  createMeeting,
  rescheduleMeeting,
  createCeremony,
  rescheduleCeremony,
  getPointOfContacts,
  unlockUsers,
} from "../../actions/meetingActions";
import { uploadDoc, uploadFile } from "../../actions/generalActions";
import {
  getPartnershipDetails,
  getSigningCeremony,
} from "../../actions/agreementActions";
import {
  getDepartments,
  getUsersPerDepartment,
} from "../../actions/generalActions";



import {
  getPartnerUsers
} from "../../actions/partnerActions";

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  users: state.meeting.users,
  data: state.agreement.partnershipDetailsData,
  siginingDetails: state.agreement.signingData,
  postsofContacts: state.meeting.postsofContacts,
  departments: state.general.departments,
  partnerUsers: state.partner.users,
  departmentUsers: state.general.usersInDepartment,
});

const mapDispatchToProps = {
  getAgreementUsers,
  createMeeting,
  rescheduleMeeting,
  getPartnershipDetails,
  getSigningCeremony,
  uploadDoc,
  uploadFile,
  createCeremony,
  rescheduleCeremony,
  getPointOfContacts,
  unlockUsers,
  getDepartments,
  getUsersPerDepartment,
  getPartnerUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(SigningCeremony);
