import api from "../utils/api";
import apiUrls from "../config/apiUrls";

import { setMessage, setLoading } from "./generalActions";
import {
  CREATE_MEETING,
  CREATE_CEREMONY,
  GET_AGREEMENT_USERS,
  GET_MEETINGS_BY_AGREEMENT,
  GET_MEETINGS_BY_AGREEMENT_FOR_USER,
  GET_MEETINGS_FOR_USER,
  GET_MEETING_BY_ID,
  GET_RELEVANT_DEPT_USERS,
  UPDATE_MEETING_MINUTES,
  UPDATE_MEETING_USER_RESPONSE,
  CANCEL_MEETING,
  GET_PONTS_OF_CONTACTS,
  UNLOCK_USERS,
  REMOVE_MEDIA,
  RESHEDULE_MEETING,
  GET_MEETINGS_BY_DATE_RANGE,
} from "../config/actionTypes";

export const getMeetingsByAgreementForUser = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getMeetingsByAgreementForUser, {
      params,
    });

    if (res.status === 200) {
      dispatch({
        type: GET_MEETINGS_BY_AGREEMENT_FOR_USER,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMeetingsForAgreement = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getMeetingsForAgreement, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_MEETINGS_BY_AGREEMENT,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateMeetingUserResponse = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.updateUserResponseForMeeting, data);

    if (res.status === 200) {
      dispatch({
        type: UPDATE_MEETING_USER_RESPONSE,
      });

      dispatch(
        setMessage(
          `You have been marked as ${
            data.Status === 1 ? "available" : "unavailable"
          } for this meeting.`
        )
      );

      setTimeout(() => {
        window.location.reload();
      }, 300);

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const meetingCancellation = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.meetingCancellation, data);

    if (res.status === 200) {
      dispatch({
        type: CANCEL_MEETING,
      });

      dispatch(setMessage(`Successfully done your Meeting Cancellation `));
      window.location.reload();

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateMeetingMinutes = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.updateMeetingMinutes, data);

    if (res.status === 200) {
      dispatch({
        type: UPDATE_MEETING_MINUTES,
      });

      dispatch(setMessage("Successfully updated meeting minutes."));

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const unlockUsers = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.post(apiUrls.unlockUsers, data);
    if (res.status === 200) {
      dispatch({
        type: UNLOCK_USERS,
      });
      dispatch(setMessage("User unlocked Successfully."));
      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const removeMedia = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.post(apiUrls.removeMedia, data);
    if (res.status === 200) {
      dispatch({
        type: REMOVE_MEDIA,
      });
      dispatch(setMessage("Media Removed Successfully."));
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);

      window.location.reload();

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMeetingById = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getMeetingById, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_MEETING_BY_ID,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMeetingsForUser = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getMeetingByUser, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_MEETINGS_FOR_USER,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMeetingsByDateRange = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getMeetingWithDateRange, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_MEETINGS_BY_DATE_RANGE,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const createMeeting = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.addMeeting, data);

    if (res.status === 201) {
      dispatch({
        type: CREATE_MEETING,
      });

      dispatch(setMessage("Successfully created a meeting."));

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const rescheduleMeeting = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.RescheduleMeeting, data);

    if (res.status === 201) {
      dispatch({
        type: RESHEDULE_MEETING,
      });

      dispatch(setMessage("Successfully rescheduled meeting."));

      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const createCeremony = (data, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await api.post(apiUrls.addSingingceremony, data);
    if (res.status === 201) {
      dispatch({
        type: CREATE_CEREMONY,
      });
      dispatch(setMessage("Successfully created a signing ceremony."));
      history.push("/agreement/partnership/track/" + data.Code);
      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const rescheduleCeremony = (data, history) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.post(apiUrls.rescheduleCeremony, data);

    if (res.status === 201) {
      dispatch({
        type: CREATE_CEREMONY,
      });

      dispatch(setMessage("Successfully reschedule signing ceremony."));
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
      history.push("/agreement/partnership/track/" + data.Code);
      return true;
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getAgreementUsers = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getAllAgreementUsers, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_AGREEMENT_USERS,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getPointOfContacts = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getPointOfContacts, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_PONTS_OF_CONTACTS,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};

export const getRelevantDeptUsers = (params) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await api.get(apiUrls.getReleventDeptUsers, { params });

    if (res.status === 200) {
      dispatch({
        type: GET_RELEVANT_DEPT_USERS,
        payload: res.data,
      });
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};
