import {

  GET_USERS,
  RESET_ALL,
  GET_USERS_BY_ID

} from "../config/actionTypes";

const initialState = {
  users: null,
  viewUser: null
};



export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_USERS_BY_ID:
      return {
        ...state,
        viewUser: action.payload,
      };
    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
