import api from "./api";
const setAuthToken = ({ access_token: token, token_type, userId }) => {
  if (token) {
    // Apply authorization token to every request if logged in
    api.defaults.headers.common["Authorization"] = `${token_type} ${token}`;
    api.defaults.headers.common["Token"] = userId;
  } else {
    // Delete auth header
    delete api.defaults.headers.common["Authorization"];
    delete api.defaults.headers.common["Token"];
  }
};
export default setAuthToken;
