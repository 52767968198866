import { getConnectUsers } from "../actions/connectActions";
import {
  OPEN_CONNECT,
  CLOSE_CONNECT,
  GET_SENT_MESSAGES,
  SEND_CONNECT,
  GET_INBOX_MESSAGES,
  RESET_ALL,
  GET_CONNECT_USERS
} from "../config/actionTypes";

const initialState = {
  isOpen: false,
  sendMessages: null,
  inbox: null,
  isUpdated: false,
  connectUsers: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_CONNECT:
      return { ...state, isOpen: true };

    case CLOSE_CONNECT:
      return { ...state, isOpen: false };

    case GET_SENT_MESSAGES:
      return { ...state, isUpdated: true, sendMessages: action.payload };

    case GET_CONNECT_USERS:

      return { ...state, connectUsers: action.payload };

    case GET_INBOX_MESSAGES:
      return { ...state, isUpdated: true, inbox: action.payload };

    case SEND_CONNECT:
      return {
        ...state,
        isUpdated: false,
      };
    case RESET_ALL:
      return initialState;


    default:
      return state;
  }
}
