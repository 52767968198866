import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Home, ExternalLink, CheckCircle, XCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Col, Row, Nav, Button, Alert } from "react-bootstrap";
import isEmpty from "is-empty";
import Skeleton from "react-loading-skeleton";
import { Form, Formik } from "formik";

import TextArea from "../../components/Form/TextArea";

export const BarCode = (props) => {
  const { i18n, t } = useTranslation();

  var Barcode = require("react-barcode");

  const lang = i18n.language === "ar" ? "ar" : "en";
  const isAr = i18n.language === "ar";

  const url = window.location.pathname;

  const lastSegment = url.split("/").pop();


  useEffect(() => {


    //     props.getAgreementFlow({
    //       lang,
    //       code: lastSegment,
    //     });
    //     props.getPartnershipDetails({ lang, code: lastSegment });
    //     props.getMOUDetails({ Code: lastSegment });
    //     props.getAgreementReviewHistory({ code: lastSegment, lang });
  }, []);

  return (
    <div className=" d-flex align-items-end py-4 htb">
      {lastSegment ? (
        <Barcode
          value={lastSegment}
          format="CODE39"
          width={1}
          className="htb"
        />
      ) : (
        <Skeleton className="mr-3" height={50} width={203} />
      )}
    </div>
  );
};

export default BarCode;
