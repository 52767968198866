import {
  GET_AGREEMENT_USERS,
  GET_MEETINGS_FOR_USER,
  GET_MEETING_BY_ID,
  GET_MEETINGS_BY_AGREEMENT,
  RESET_ALL,
  GET_MEETINGS_BY_AGREEMENT_FOR_USER,
  GET_RELEVANT_DEPT_USERS,
  GET_PONTS_OF_CONTACTS,
  UNLOCK_USERS,
  GET_MEETINGS_BY_DATE_RANGE
} from "../config/actionTypes";

const initialState = {
  users: null,
  myMeetings: null,
  chosenMeeting: null,
  meetingsPerAgreement: null,
  meetingsPerAgreementForUser: null,
  postsofContacts: null,
  daterangeMeetings: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MEETINGS_BY_AGREEMENT_FOR_USER:
      return {
        ...state,
        meetingsPerAgreementForUser: action.payload,
      };
    case GET_MEETINGS_BY_AGREEMENT:
      return {
        ...state,
        meetingsPerAgreement: action.payload,
      };




    case GET_MEETING_BY_ID:
      return {
        ...state,
        chosenMeeting: action.payload,
        myMeetings: state.myMeetings
          ? state.myMeetings.map((meeting) => {
            // Updating meeting details without calling entire list api
            if (meeting.Id === action.payload.Id) {
              return action.payload;
            }
            return meeting;
          })
          : null,
      };
    case GET_MEETINGS_FOR_USER:
      return {
        ...state,
        myMeetings: action.payload,
      };


    case GET_MEETINGS_BY_DATE_RANGE:
      return {
        ...state,
        daterangeMeetings: action.payload,
      };

    case GET_PONTS_OF_CONTACTS:
      return {
        ...state,
        postsofContacts: action.payload,
      };
    case GET_RELEVANT_DEPT_USERS:
      return {
        ...state,
        users: [
          ...state.users,
          {
            label: "Involved Department Users",
            options: action.payload?.map((user) => ({
              value: { UserId: user.UserId },
              label: user.FirstName
                ? `${user.FirstName}  ${user.LastName} - ${user.DepartmentName}`
                : `${user.Email} - ${user.DepartmentName}`,
            })),
          },
        ],
      };
    case GET_AGREEMENT_USERS:
      return {
        ...state,
        users: [
          {
            label: "Primary Contacts",
            options: action.payload.POC?.map((user) => ({
              value: { UserId: user.UserId },
              label: user.FirstName
                ? `${user.FirstName}  ${user.LastName} - ${user.IsPartner ? "Partner" : user.DepartmentName
                }`
                : `${user.Email} - ${user.IsPartner ? "Partner" : user.DepartmentName
                }`,
            })),
          },

          ...(action.payload?.SecondaryParties?.length !== 0
            ? [
              {
                label: "Secondary Contacts",
                options: action.payload.SecondaryParties?.map((user) => ({
                  value: { UserId: user.UserId },
                  label: user.FirstName
                    ? `${user.FirstName}  ${user.LastName} - ${user.IsPartner ? "Partner" : user.DepartmentName
                    }`
                    : `${user.Email} - ${user.IsPartner ? "Partner" : user.DepartmentName
                    }`,
                })),
              },
            ]
            : []),

          ...(action.payload?.Admins?.length !== 0
            ? [
              {
                label: "Admin Users",
                options: action.payload.Admins?.map((user) => ({
                  value: { UserId: user.UserId },
                  label: user.FirstName
                    ? `${user.FirstName}  ${user.LastName} - Admin`
                    : `${user.Email} - Admin`,
                })),
              },
            ]
            : []),

          ...(action.payload?.DepartmentUsers?.length !== 0
            ? [
              {
                label: "Involved Department Users",
                options: action.payload.DepartmentUsers?.map((user) => ({
                  value: { UserId: user.UserId },
                  label: user.FirstName
                    ? `${user.FirstName}  ${user.LastName} - ${user.DepartmentName}`
                    : `${user.Email} - ${user.DepartmentName}`,
                })),
              },
            ]
            : []),
        ],
      };

    case RESET_ALL:
      return initialState;
    default:
      return state;
  }
}
