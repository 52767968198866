import {
  GET_PARTNER_DROPDOWNS,
  GET_ERRORS,
  GET_PARTNER_REVIEW,
  GET_PARTNER_USERS,
  GET_DET_USERS,
  CLEAR_PARTNER,
  CONFIRM_EMAIL,
  REGISTER_PARTNER,
  GET_PARTNERS,
  SAVE_PARTNER_PROFILE,
  ADD_PARTNER_USER,
  INVITE_PARTNER,
  GET_PARTNER_CLASSIFICATION,
  ADD_PARTNER,
  GET_OTHER_EMIRATES,
  GET_PARTNER_INFO,
} from "../config/actionTypes";
import api from "../utils/api";
import apiUrls from "../config/apiUrls";
import isEmpty from "is-empty";
import { setMessage, setLoading } from "../actions/generalActions";
import { clearErrors } from "./generalActions";
import { isArabic } from "../i18n";

export const getOtherEmirates = (params) => async (dispatch) => {
  try {
    const res = await api.get(apiUrls.getOtherEmirates, { params });

    dispatch({
      type: GET_OTHER_EMIRATES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getRegisterDropdowns = (params) => async (dispatch) => {
  try {
    const res = await api.get(apiUrls.partnerDropdowns, { params });

    dispatch({
      type: GET_PARTNER_DROPDOWNS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const registerPartner = (userData, userId, id) => async (dispatch) => {
  dispatch(clearErrors());

  const {
    userEmail,
    userConfirmPassword,
    userPassword,
    userMobile,
    partnerEmail,
    partnerLicenseAuthorityId,
    partnerFreeZoneId,
    partnerOtherEmirateId,
    partnerLicenseNo,
    partnerLicenseIssueDate,
    partnerLicenseExpiryDate,
    partnerSectorId,
    partnerPOBox,
    partnerLandLineNo,
    partnerTransEnTradeName,
    partnerTransEnOwnerName,
    partnerTransEnAuthorizedRepName,
    partnerTransEnAuthorizedRepDesig,
    partnerTransEnCompanyAddress,
    partnerTransArTradeName,
    partnerTransArOwnerName,
    partnerTransArAuthorizedRepName,
    partnerTransArAuthorizedRepDesig,
    partnerTransArCompanyAddress,
    partnerCompanyLogoFile,
    partnerTradeLicenceDocs,
    partnerFax,
    partnerWebAddress,
    captcha,
    invitationId,
  } = userData;

  const formData = new FormData();

  formData.append("User.Email", userEmail);
  formData.append("User.ConfirmPassword", userConfirmPassword);
  formData.append("User.Password", userPassword);
  formData.append("User.Mobile", userMobile);
  formData.append("Partner.Email", partnerEmail);
  formData.append("Partner.CompanyLogoFile", partnerCompanyLogoFile);
  formData.append("Partner.LicenseNo", partnerLicenseNo);
  formData.append("Partner.LicenseIssueDate", partnerLicenseIssueDate);
  formData.append("Partner.LicenseExpiryDate", partnerLicenseExpiryDate);
  formData.append("Partner.SectorId", +partnerSectorId);
  formData.append("Partner.POBox", partnerPOBox);
  formData.append("Partner.Fax", partnerFax);
  formData.append("Partner.WebAddress", partnerWebAddress);
  formData.append("Partner.LandLineNo", partnerLandLineNo);
  formData.append("Partner.TradeLicenceDocs", partnerTradeLicenceDocs);

  formData.append("PartnerTransEn.TradeName", partnerTransEnTradeName);
  formData.append("PartnerTransEn.OwnerName", partnerTransEnOwnerName);
  formData.append(
    "PartnerTransEn.AuthorizedRepName",
    partnerTransEnAuthorizedRepName
  );
  formData.append(
    "PartnerTransEn.AuthorizedRepDesig",
    partnerTransEnAuthorizedRepDesig
  );
  formData.append(
    "PartnerTransEn.CompanyAddress",
    partnerTransEnCompanyAddress
  );

  formData.append("PartnerTransAr.TradeName", partnerTransArTradeName);
  formData.append("PartnerTransAr.OwnerName", partnerTransArOwnerName);
  formData.append(
    "PartnerTransAr.AuthorizedRepName",
    partnerTransArAuthorizedRepName
  );
  formData.append(
    "PartnerTransAr.AuthorizedRepDesig",
    partnerTransArAuthorizedRepDesig
  );
  formData.append(
    "PartnerTransAr.CompanyAddress",
    partnerTransArCompanyAddress
  );

  formData.append("Partner.LicenseAuthorityId", +partnerLicenseAuthorityId);
  formData.append(
    "Partner.FreeZoneId",
    +partnerLicenseAuthorityId === 2 ? +partnerFreeZoneId : null
  );
  formData.append("Partner.OtherEmirateId", +partnerOtherEmirateId || null);
  formData.append("CaptchaResponce", captcha);

  let url = apiUrls.partnerRegister;

  if (id) {
    url = apiUrls.partnerReregister;

    formData.append("Partner.Id", id);
    formData.append("User.Id", userId);

    !partnerCompanyLogoFile && formData.delete("Partner.CompanyLogoFile");
    !partnerTradeLicenceDocs && formData.delete("Partner.TradeLicenceDocs");
  }

  if (invitationId) formData.append("Partner.InvitationId", invitationId);

  try {
    const res = await api.post(url, formData);

    if (res.status === 201 || res.status === 200) {
      dispatch({
        type: REGISTER_PARTNER,
      });

      return true;
    }
  } catch (err) {
    let error = "SomethingError";

    if (!isEmpty(err.response.data) && err.response.status < 500) {
      error = err.response.data.Message;
    }

    dispatch({
      type: GET_ERRORS,
      payload: { registerError: error },
    });

    return false;
  }
};

export const savePartnerProfile =
  (data, partnerId, history) => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const {
      partnerEmail,
      partnerLicenseAuthorityId,
      partnerFreeZoneId,
      partnerLicenseNo,
      partnerLicenseIssueDate,
      partnerLicenseExpiryDate,
      partnerSectorId,
      partnerPOBox,
      partnerLandLineNo,
      partnerTransEnTradeName,
      partnerTransEnOwnerName,
      partnerTransEnAuthorizedRepName,
      partnerTransEnAuthorizedRepDesig,
      partnerTransEnCompanyAddress,
      partnerTransArTradeName,
      partnerTransArOwnerName,
      partnerTransArAuthorizedRepName,
      partnerTransArAuthorizedRepDesig,
      partnerTransArCompanyAddress,
      partnerCompanyLogoFile,
      partnerTradeLicenceDocs,
      partnerFax,
      partnerWebAddress,
    } = data;

    const formData = new FormData();

    formData.append("Partner.Id", partnerId);
    formData.append("Partner.Email", partnerEmail);
    formData.append("Partner.CompanyLogoFile", partnerCompanyLogoFile);
    formData.append("Partner.LicenseNo", partnerLicenseNo);
    formData.append("Partner.LicenseIssueDate", partnerLicenseIssueDate);
    formData.append("Partner.LicenseExpiryDate", partnerLicenseExpiryDate);
    formData.append("Partner.SectorId", +partnerSectorId);
    formData.append("Partner.POBox", partnerPOBox);
    formData.append("Partner.Fax", partnerFax);
    formData.append("Partner.WebAddress", partnerWebAddress);
    formData.append("Partner.LandLineNo", partnerLandLineNo);
    formData.append("Partner.TradeLicenceDocs", partnerTradeLicenceDocs);

    formData.append("PartnerTransEn.TradeName", partnerTransEnTradeName);
    formData.append("PartnerTransEn.OwnerName", partnerTransEnOwnerName);
    formData.append(
      "PartnerTransEn.AuthorizedRepName",
      partnerTransEnAuthorizedRepName
    );
    formData.append(
      "PartnerTransEn.AuthorizedRepDesig",
      partnerTransEnAuthorizedRepDesig
    );
    formData.append(
      "PartnerTransEn.CompanyAddress",
      partnerTransEnCompanyAddress
    );

    formData.append("PartnerTransAr.TradeName", partnerTransArTradeName);
    formData.append("PartnerTransAr.OwnerName", partnerTransArOwnerName);
    formData.append(
      "PartnerTransAr.AuthorizedRepName",
      partnerTransArAuthorizedRepName
    );
    formData.append(
      "PartnerTransAr.AuthorizedRepDesig",
      partnerTransArAuthorizedRepDesig
    );
    formData.append(
      "PartnerTransAr.CompanyAddress",
      partnerTransArCompanyAddress
    );

    formData.append("Partner.LicenseAuthorityId", +partnerLicenseAuthorityId);
    formData.append(
      "Partner.FreeZoneId",
      +partnerLicenseAuthorityId === 2 ? +partnerFreeZoneId : null
    );

    try {
      const res = await api.post(apiUrls.updatePartnerProfile, formData);

      if (res.status === 200) {
        dispatch({
          type: SAVE_PARTNER_PROFILE,
        });

        dispatch(setMessage("Successfully updated the partner profile."));
        return true;
        // setTimeout(() => {
        //   history.push("/my-company-profile");
        // }, 2000);
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getPartnerDetails = (id) => async (dispatch) => {
  dispatch(clearPartner());
  dispatch(setLoading(true));
  try {
    const res = await api.get(
      `${apiUrls.getPartnerReview}?Id=${id}&langCode=${isArabic ? "ar" : "en"}`
    );

    dispatch({
      type: GET_PARTNER_REVIEW,
      payload: res.data,
    });

    dispatch(getPartnerUsers(id));

    return res.data;
    return 1;
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getPartnerById = (id, params) => async (dispatch) => {
  try {
    const res = await api.get(`${apiUrls.getParner}/${id}?`, { params });
    if (res.status === 200) {
      dispatch({
        type: GET_PARTNER_INFO,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getPartnerUsers =
  (id, removeUser = false) =>
    async (dispatch) => {
      try {
        const res = await api.get(
          `${apiUrls.getPartnerUsers}?langCode=${isArabic ? "ar" : "en"
          }&partnerId=${id}&removeUser=${removeUser}`
        );

        dispatch({
          type: GET_PARTNER_USERS,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
      }
    };


export const getDETUsers =
  (id, removeUser = false) =>
    async (dispatch) => {
      try {
        const res = await api.get(
          `${apiUrls.getPartnerUsers}?langCode=${isArabic ? "ar" : "en"
          }&partnerId=${id}&removeUser=${removeUser}`
        );

        dispatch({
          type: GET_DET_USERS,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
      }
    };



export const updateReview =
  (data, history, partnerUser) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const res = await api.put(apiUrls.updateReview, data);

      if (res.status === 200) {
        let notificationMessage =
          data.Status === 1
            ? `The request received from ${partnerUser} has been approved.`
            : "The request has been rejected and a notification email has been sent to the respective user. ";
        dispatch(setMessage(notificationMessage));
        setTimeout(() => {
          history.push("/dashboard");
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const confirmEmail = (id) => async (dispatch) => {
  try {
    const res = await api.get(apiUrls.confirmEmail + id);
    if (res.status === 200) {
      dispatch({ type: CONFIRM_EMAIL });
    }
  } catch (error) {
    console.log(error);
  }
};

export const clearPartner = () => {
  return {
    type: CLEAR_PARTNER,
  };
};

export const checkPartnerEmailExists = async (email) => {
  try {
    let res = await api.get(apiUrls.checkPartnerExists, { params: { email } });

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkUserEmailExists = async (email) => {
  try {
    let res = await api.get(apiUrls.checkUserExists, { params: { email } });

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPartners = (params) => async (dispatch) => {
  try {
    const res = await api.get(apiUrls.getPartners, { params });

    dispatch({
      type: GET_PARTNERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addPartnerUser = (data) => async (dispatch) => {
  try {
    dispatch(clearErrors());
    const res = await api.post(apiUrls.addPartnerUser, data);

    if (res.status === 201) {
      dispatch({
        type: ADD_PARTNER_USER,
      });

      dispatch(setMessage("Successfully added a user."));
      dispatch(getPartnerDetails(data.PartnerId));
    }

    return res.status === 201;
  } catch (err) {
    let error = "SomethingError";

    if (!isEmpty(err.response.data) && err.response.status < 500) {
      error = err.response.data.Message;
    }

    dispatch({
      type: GET_ERRORS,
      payload: { addPartnerUserError: error },
    });

    return false;
  }
};

export const invitePartner = (data) => async (dispatch) => {
  try {
    const res = await api.post(apiUrls.invitePartner, data);

    if (res.status === 200) {
      dispatch({
        type: INVITE_PARTNER,
      });
      dispatch(setMessage("Successfully sent the partner invitation."));

      return res.data;
    }
  } catch (error) { }
};

export const addPartner = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const {
      userEmail,
      userMobile,
      partnerEmail,
      partnerLicenseNo,
      partnerSectorId,
      partnerPOBox,
      partnerLandLineNo,
      partnerTransEnTradeName,
      partnerTransEnAuthorizedRepName,
      partnerTransEnAuthorizedRepDesig,
      partnerTransEnCompanyAddress,
      partnerTransArTradeName,
      partnerTransArAuthorizedRepName,
      partnerTransArAuthorizedRepDesig,
      partnerTransArCompanyAddress,
      partnerCompanyLogoFile,
      partnerWebAddress,
      partnerTransEnCompanyProfile,
      partnerTransArCompanyProfile,
      partnerTransEnOwnerName,
      partnerTransArOwnerName,
      ServiceKey,
      partnerOtherEmirateId,
      PartnerId,

      partnerFax,
      partnerLicenseIssueDate,
      partnerLicenseExpiryDate,
      partnerLicenseAuthorityId,
      partnerFreeZoneId,
    } = data;

    const formData = new FormData();

    PartnerId && formData.append("Partner.Id", PartnerId);

    formData.append("User.Email", userEmail);
    formData.append("User.Mobile", userMobile);
    formData.append("Partner.Email", partnerEmail);
    formData.append("Partner.CompanyLogoFile", partnerCompanyLogoFile);
    formData.append("Partner.LicenseNo", partnerLicenseNo);
    formData.append("Partner.SectorId", +partnerSectorId);
    formData.append("Partner.POBox", partnerPOBox);
    formData.append("Partner.WebAddress", partnerWebAddress);
    formData.append("Partner.LandLineNo", partnerLandLineNo);
    formData.append("PartnerTransEn.TradeName", partnerTransEnTradeName);
    formData.append(
      "PartnerTransEn.AuthorizedRepName",
      partnerTransEnAuthorizedRepName
    );
    formData.append(
      "PartnerTransEn.AuthorizedRepDesig",
      partnerTransEnAuthorizedRepDesig
    );
    formData.append(
      "PartnerTransEn.CompanyAddress",
      partnerTransEnCompanyAddress
    );

    formData.append("PartnerTransAr.TradeName", partnerTransArTradeName);
    formData.append(
      "PartnerTransAr.AuthorizedRepName",
      partnerTransArAuthorizedRepName
    );
    formData.append(
      "PartnerTransAr.AuthorizedRepDesig",
      partnerTransArAuthorizedRepDesig
    );
    formData.append(
      "PartnerTransAr.CompanyAddress",
      partnerTransArCompanyAddress
    );
    formData.append(
      "PartnerTransEn.CompanyProfile",
      partnerTransEnCompanyProfile
    );
    formData.append(
      "PartnerTransAr.CompanyProfile",
      partnerTransArCompanyProfile
    );
    formData.append("PartnerTransEn.OwnerName", partnerTransEnOwnerName);
    formData.append("PartnerTransAr.OwnerName", partnerTransArOwnerName);
    formData.append("ServiceKey", ServiceKey);
    formData.append("Partner.OtherEmirateId", partnerOtherEmirateId);
    formData.append("Partner.Fax", partnerFax || "");
    formData.append("Partner.LicenseIssueDate", partnerLicenseIssueDate || "");
    formData.append(
      "Partner.LicenseExpiryDate",
      partnerLicenseExpiryDate || ""
    );
    formData.append(
      "Partner.LicenseAuthorityId",
      partnerLicenseAuthorityId || ""
    );
    formData.append("Partner.FreeZoneId", partnerFreeZoneId || "");

    const res = await api.post(apiUrls.addPartner, formData);

    if (res.status === 200 || res.status === 201) {
      dispatch({
        type: ADD_PARTNER,
      });
      return res.data;
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getPartnerTypes = () => async (dispatch) => {
  try {
    const res = await api.get(
      `${apiUrls.getPartnerClassifications}?lang=${isArabic ? "ar" : "en"}`
    );

    if (res.status === 200) {
      dispatch({
        type: GET_PARTNER_CLASSIFICATION,
        payload: res.data,
      });
    }
  } catch (error) { }
};
