import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Home } from "react-feather";
import { nanoid } from "nanoid";

import * as Yup from "yup";
import moment from "moment";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { TagInput } from "rsuite";
import { ExternalLink, PlusCircle, XCircle } from "react-feather";
import { Row, Col, Nav, Alert, Button, FormCheck } from "react-bootstrap";
import Tooltip from "../../components/Tooltip";
import TextField from "../../components/Form/Textfield";
import TextArea from "../../components/Form/TextArea";
import Select from "../../components/Form/Select";
import ReadOnlyRow from "./ReadOnlyRow";
import { useLocation } from "react-router-dom";

const SigningCeremony = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language === "ar" ? "ar" : "en";

  const langCode = i18n.language === "ar" ? "ar" : "en";


  const location = useLocation();



  console.log("55555555", location.state.partnerid);


  const [externalusers, setExternalusers] = useState([]);
  const [selecetdusers, setSelecetdusers] = useState([]);
  const [selecetdPartner, setSelecetPartners] = useState([]);


  const [departmentList, setDepartmentList] = useState(props.siginingDetails?.AttendeesDepartment ? props.siginingDetails?.AttendeesDepartment : [])


  const [emailErr, setEmailErr] = useState(false);
  const [isSigningCeremony, setSigningCeremony] = useState(true);
  const [selectedUsers, setSelectedUserValue] = useState([]);
  const { agreementId } = props.match.params;
  const [contacts, setContacts] = useState([]);
  const [addFormData, setAddFormData] = useState({
    department: "",
    UserId: selectedUsers,
    id: "",
  });

  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  const handleDeleteClick = (contactId) => {
    var lists = departmentList.filter(x => {
      return x.DepId != contactId;
    })
    setDepartmentList(lists)

  };

  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  useEffect(() => {



    props.getAgreementUsers({ lang, code: agreementId });
    props.getPartnershipDetails({ lang, code: agreementId });
    // props.partnerUsers({ langCode, IsDEDEntity: false, isApproved: true });
    props.getPartnerUsers(location.state.partnerid);
    props.getSigningCeremony({ lang, code: agreementId });
    props.getPointOfContacts({ lang, code: agreementId });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    if (!props.departments) props.getDepartments({ LangCode: lang });
  }, []);

  const handleSubmit = async (values) => {


    if (props.siginingDetails == null) {
      const res = props.createCeremony(
        {
          Code: agreementId,
          MeetingRequestType: 2,
          Attendees: departmentList.map((data, i) =>


          ({
            userId: data.Users[0].Id || data.Users[0].value,
          })),
          PartnerUsers: selecetdPartner.map((data) => ({
            UserId: data.value,
          })),
          Date: values.Date,
          ExternalUsers: externalusers,
          From: values.From,
          Location: values.Location,
          Message: values.Message,
          Subject: values.Subject,
          To: values.To,
          InviteToUpload: values.InviteToUpload,
          IsBothPartyUpload: isSigningCeremony,
        },
        props.history
      );
    }

    if (props.siginingDetails != null) {
      const res = props.rescheduleCeremony(
        {
          Code: agreementId,
          MeetingRequestType: 2,
          Attendees: departmentList.map(
            (data) => ({
              userId: data.Users[0].Id || data.Users[0].value,
            })


          ),
          PartnerUsers: selecetdPartner.map((data) => ({
            UserId: data.value,
          })),
          Date: values.Date,
          ExternalUsers: externalusers,
          From: values.From,
          Location: values.Location,
          Message: values.Message,
          Subject: values.Subject,
          To: values.To,
          IsBothPartyUpload: isSigningCeremony,
          Id: props.siginingDetails.Id,
          InviteToUpload: values.InviteToUpload,
        },
        // props.history
      );


    }




  };



  const getEmployeesOfDept = (Id) => {
    if (Id) props.getUsersPerDepartment({ Id, removeUser: true });
  };
  return (
    <>
      <div className="d-flex mt-n4 mb-1">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i>
                {" "}
                <Home size={14} />{" "}
              </i>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={`/agreement/partnership/track/${agreementId}`}>
              {" "}
              Manage Agreement{" "}
            </Link>
          </li>
          <li className="breadcrumb-item">{t("Signing Ceremony")}</li>
          <li className="breadcrumb-item">{agreementId}</li>
        </ul>
      </div>
      <div className="page-body navbar-page">
        <Row>
          <Col xs={12}>
            <div className="card">
              <div className="card-block">
                <Row>
                  <Col lg={10}>
                    {" "}
                    <h4 className="text-primary mb-3">Signing Ceremony</h4>
                  </Col>
                  <Col lg={2} className="text-right">
                    <button
                      className="btn btn-blue-green "
                      onClick={() => props.history.goBack()}
                    >
                      Go back
                    </button>
                  </Col>
                </Row>
                <Formik
                  initialValues={{
                    Date: moment(
                      props.siginingDetails?.EventsOn
                        ? props.siginingDetails?.EventsOn
                        : ""
                    ).format("YYYY-MM-DD"),
                    From: props.siginingDetails?.From
                      ? props.siginingDetails?.From
                      : "",
                    To: props.siginingDetails?.To
                      ? props.siginingDetails?.To
                      : "",
                    Subject: props.data?.ServiceName || "",
                    Message: props.siginingDetails?.Description
                      ? props.siginingDetails?.Description
                      : "",
                    Location: props.siginingDetails?.Location
                      ? props.siginingDetails?.Location
                      : "",
                    ExternalUsers: props.siginingDetails?.ExteranlUsers
                      ? props.siginingDetails?.ExteranlUsers.map((data) => data)
                      : [],
                    Attendees: [],
                    PartnerUsers: [],
                    InviteToUpload: [],
                  }}
                  validationSchema={Yup.object().shape({
                    Date: Yup.date().required().label("Date"),
                    From: Yup.string().required().label("From (Time)"),
                    To: Yup.string()
                      .required()
                      .test(
                        "is-greater",
                        "To (Time) should be greater",
                        function (value) {
                          const { From } = this.parent;
                          return moment(value, "HH:mm").isAfter(
                            moment(From, "HH:mm")
                          );
                        }
                      )
                      .label("To (Time)"),
                    Subject: Yup.string().required().label("Subject"),
                  })}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({ errors, touched, handleChange, values }) => (
                    <Form>
                      <Row className="mt-5">
                        <div className="form-group col-md-4 ">
                          <label className="small text-nowrap ">Date</label>
                          <TextField
                            type="date"
                            name="Date"
                            min={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label className="small text-nowrap ">
                            From(Time)
                          </label>
                          <TextField type="time" name="From" />
                        </div>
                        <div className="form-group col-md-4">
                          <label className="small text-nowrap ">To(Time)</label>
                          <TextField type="time" name="To" />
                        </div>
                        <div className="form-group col-md-12">
                          <label className="small text-nowrap ">Subject</label>
                          <TextField type="text" name="Subject" />
                        </div>
                        <div className="form-group col-md-12">
                          <label className="small text-nowrap ">
                            Message (if any)
                          </label>
                          <TextArea name="Message" />
                        </div>
                        <div className="form-group col-sm-12">
                          <label className="small">
                            Invite DET party and other members

                            {" "}
                            <Tooltip text="Choose the members to invite to the signing ceremony who are already on the platform" />
                          </label>
                        </div>

                        <div className="col-lg-12">
                          <Formik
                            initialValues={{}}
                            validationSchema={Yup.object().shape({})}
                            onSubmit={async (values, { resetForm }) => {
                              const depts = props.departments.filter(
                                (e) => e.Id == addFormData.department
                              );

                              console.log("depts", depts)
                              console.log("values", values)
                              console.log("addFormData", addFormData)

                              const newAddList = {
                                DepId: depts[0].Id,
                                DepName: depts[0].Name,
                                Users: selecetdusers.map((data) =>
                                ({
                                  value: data.value,
                                  label: data.label,

                                })
                                ),
                              }


                              const newContacts = [...departmentList, newAddList];
                              // setContacts(newContacts);

                              setDepartmentList(newContacts)
                              resetForm();
                            }}
                            enableReinitialize={true}
                          >
                            {({ errors, touched, handleChange, values }) => (
                              <Form className="row ">
                                <div className="form-group col-sm-5">
                                  <Field
                                    as="select"
                                    name="department"
                                    disabled={!props.departments}
                                    className={`form-control ${touched.dept && errors.dept
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                    onChange={(e) => {
                                      handleAddFormChange(e);
                                      getEmployeesOfDept(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Department</option>
                                    {props.departments &&
                                      props.departments.map((item) => (
                                        <option
                                          key={item.Id}
                                          value={item.Id}
                                          label={item.Name}
                                        >
                                          {item.Name}
                                        </option>
                                      ))}
                                  </Field>
                                  <ErrorMessage
                                    name="dept"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="form-group col-sm-5 offset-md-1">
                                  <Select
                                    isMulti
                                    name="UserId"
                                    placeholder="Choose Users"
                                    disabled={!props.departmentUsers}
                                    value={props.departmentUsers
                                      ?.filter((dept) =>
                                        values.UserId?.includes(dept.Id)
                                      )
                                      .map((dept) =>
                                      ({
                                        value: dept.Id,
                                        label: dept.FirstName,
                                        Email: dept.Email,
                                        FirstName: dept.FirstName,
                                        Id: dept.Id,
                                        LastName: dept.LastName,
                                        MeetingStatus: 0
                                      })



                                      )}
                                    options={props.departmentUsers?.map(
                                      (dept) => ({
                                        value: dept.Id,
                                        label: dept.FirstName,
                                      })
                                    )}
                                    onChange={(e) => {
                                      e.map((val) => {
                                        setSelecetdusers([
                                          ...selecetdusers,
                                          val,
                                        ]);
                                      });

                                    }}
                                  />
                                  <ErrorMessage
                                    name="UserId"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="form-group col-sm-1 ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-sm px-3 mb-3 "
                                  >
                                    <PlusCircle size="14" />
                                  </button>{" "}
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>






                        <div className="form-group col-lg-12 table-responsive small pb-0">
                          <form>
                            <table className="table table-hover table-striped table-condensed">
                              <thead className="bg-dark">
                                <tr>
                                  <th>Department</th>
                                  <th>Users</th>
                                  <th>Action</th>
                                </tr>
                              </thead>



                              {departmentList
                                .length > 0 ? (
                                <>
                                  {departmentList.map(
                                    (data) => (
                                      <tbody>
                                        <tr key={data.DepId}>
                                          <td>{data?.DepName}</td>
                                          {data.Users?.map((usr) => (
                                            <div>{usr.FirstName || usr.label}</div>
                                          ))}
                                          <td>
                                            <button
                                              onClick={() =>
                                                handleDeleteClick(data.DepId)
                                              }
                                              type="button"
                                              className="text-danger text-nowrap border-0 bg-transparent"
                                            >
                                              <XCircle size={11} />
                                              Remove
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </table>
                          </form>
                        </div>
                        <hr />
                        <hr />





                        <div className="col-sm-12 form-group">
                          <label className="small">
                            Choose Partner Users{" "}
                          </label>


                          {console.log("props.partners", props.partners)
                          }
                          <Select
                            name="PartnerUsers"
                            isMulti
                            placeholder="Choose partner users"
                            value={
                              !!values.PartnerUsers
                                ? props.partnerUsers
                                  ?.map((item) => ({
                                    value: item.Id,
                                    label: `${item.Email} (${item.Email})`,
                                  }))
                                  .find((item) => item.value === values.PartnerId)
                                : ""
                            }
                            options={props.partnerUsers?.map((item) => ({
                              value: item.Id,
                              label: `${item.Email} (${item.Email})`,
                            }))}


                            onChange={(e) => {

                              e.map((val) => {
                                setSelecetPartners([
                                  ...selecetdPartner,
                                  val,
                                ]);
                              });

                            }}
                          />
                        </div>


                        <div className="form-group col-md-12">
                          <label className="small text-nowrap ">
                            Invite external team members{" "}
                            <Tooltip text="Enter the email addresses separated by commas to invite team members who are not registered" />
                          </label>
                          <TagInput
                            name="ExternalUsers"
                            className="tagin"
                            type="email"
                            defaultValue={values.ExternalUsers}
                            trigger={["Enter", "Space", "Comma"]}
                            menuStyle={{ width: 1000 }}
                            onCreate={(value, item) => {
                              if (re.test(item.value)) {
                                setEmailErr(false);
                                setExternalusers(values.ExternalUsers);
                              } else {
                                setEmailErr(true);
                                setExternalusers(values.ExternalUsers);
                              }
                            }}
                          />

                          {emailErr ? (
                            <div className="pt-2 emailerr">
                              Please choose valid Email
                            </div>
                          ) : (
                            ""
                          )}
                        </div>





                        <div className="form-group col-md-12 ">
                          <label className="small text-nowrap ">
                            Would you like to allow both the parties to upload a
                            signed agreement
                          </label>

                          <span className="ms-2 ml-4">
                            <FormCheck
                              inline
                              name="IsSigningCeremonyNeeded"
                              type="checkbox"
                              label="Yes"
                              checked={isSigningCeremony == true}
                              onChange={(e) => {
                                setSigningCeremony(
                                  (isSigningCeremony) => !isSigningCeremony
                                );
                              }}
                              id="test"
                            />
                          </span>
                          <span className="ms-2">
                            <FormCheck
                              inline
                              name="IsSigningCeremonyNeeded"
                              type="checkbox"
                              label="No"
                              checked={isSigningCeremony == false}
                              onChange={(e) => {
                                setSigningCeremony(
                                  (isSigningCeremony) => !isSigningCeremony
                                );
                              }}
                              id="test"
                            />
                          </span>
                        </div>
                        {isSigningCeremony ? (
                          <div className="col-sm-12 form-group">
                            <label className="small">
                              Choose the Participants to upload signed document{" "}
                              <Tooltip text=" Choose the members from the first and second party entities to unlock to upload signed document" />
                            </label>
                            <Select
                              isMulti
                              name="InviteToUpload"
                              defaultValue={
                                props.siginingDetails?.InviteToUpload
                                  ? props.siginingDetails?.InviteToUpload.map(
                                    (data) => ({
                                      value: data.Id,
                                      label: data.Email,
                                    })
                                  )
                                  : []
                              }
                              placeholder="Choose participants"
                              options={props.postsofContacts?.map((dept) => ({
                                value: dept.UserId,
                                label: dept.Email,
                              }))}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="form-group col-md-12">
                          <label className="small text-nowrap ">Location</label>
                          <TextField name="Location" />
                        </div>
                      </Row>
                      <hr />

                      <Row className="my-3 d-flex justify-content-center">
                        <div className="col col-sm-auto">
                          <button
                            onClick={() => props.history.goBack()}
                            type="button"
                            className="btn btn-secondary btn-block px-3 px-sm-5"
                          >
                            {props.siginingDetails == null
                              ? "Cancel"
                              : "Discard changes"}
                          </button>
                        </div>
                        <div className="col col-sm-auto">
                          <button
                            type="submit"
                            className="btn btn-blue-green btn-block px-3 px-sm-5"
                          >
                            {props.siginingDetails == null
                              ? "Submit"
                              : "Update changes"}
                          </button>
                        </div>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SigningCeremony;
