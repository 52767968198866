export function setUser(data) {
  removeUser();
  localStorage.setItem("auth", JSON.stringify(data));
  return true;
}

export function getUser() {
  return JSON.parse(localStorage.getItem("auth") || "{}");
}

export function removeUser() {
  localStorage.removeItem("auth");
}
