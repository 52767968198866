import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function ({ text }) {
  return (
    <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>}>
      <i className="icofont icofont-info-circle text-primary"></i>
    </OverlayTrigger>
  );
}
